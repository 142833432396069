(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
'use strict';



var app = angular.module('frontendApp',
[
  'ngAnimate', 'ui.bootstrap', 'ngCookies', 'angular-jwt', 'ngLodash', 'ngResource', 'ngSanitize', 'angular-storage',
  'angular-stripe', 'ngTouch', 'pascalprecht.translate', 'ui.router', 'breeze.angular',
  'frontendApp.config.bootstrap', 'restangular', 'auth0.lock', 'auth0.auth0'
]);

require('./services');
require('./controllers');
require('./directives');
require('./filters');


  app.config([
    '$httpProvider', '$provide', '$stateProvider', '$urlRouterProvider', 'lockProvider', '$translateProvider', '$logProvider', '$compileProvider',
    'stripeProvider', 'ConfigServiceProvider', 'RestangularProvider', 'angularAuth0Provider', function ($httpProvider, $provide, $stateProvider, $urlRouterProvider, lockProvider, $translateProvider,
                                                        $logProvider, $compileProvider, stripeProvider, ConfigServiceProvider, RestangularProvider, angularAuth0Provider) {
      //$logProvider.debugEnabled(ConfigServiceProvider.$get().getDebugFlag());
        $logProvider.debugEnabled(true);
      $compileProvider.debugInfoEnabled(ConfigServiceProvider.$get().getDebugFlag());
      $httpProvider.interceptors.push('LoginInterceptor');
      stripeProvider.setPublishableKey(ConfigServiceProvider.$get().getStripePublishableKey());
      RestangularProvider.setBaseUrl(ConfigServiceProvider.$get().getMiscApiEndpoint());
      $provide.decorator('$log', [
        '$delegate', function ($delegate) {
          var origDebug = $delegate.debug;
          var origError = $delegate.error;

          $delegate.debug = function () {
            var args = [].slice.call(arguments);
            //args[0] = [new Date().toString(), ': ', args[0]].join('');

            //args[0] = ['[' + moment().format() + '] ', args[0]].join('');
            args.splice(0, 0, '[' + moment().format() + ']');

            //modifiedArguments[0] = ['[' + $window.moment().format() + '::' + context + ']  '] + modifiedArguments[0];
            // Send on our enhanced message to the original debug method.
            //console.debug(args[0]);
            origDebug.apply(null, args);
          };
          $delegate.debug.logs = [];
          $delegate.error = function () {
            var args = [].slice.call(arguments);
            //args[0] = [new Date().toString(), ': ', args[0]].join('');
            args[0] = ['[' + moment().format() + '] ', args[0]].join('');
            // Send on our enhanced message to the original debug method.
            origError.apply(null, args);
          };

          return $delegate;
        }
      ]);


      //$translateProvider.useStaticFilesLoader({
      //  prefix: 'http://constructo.int/api/translations/',
      //  suffix: ''

      //});
      //$translateProvider.useUrlLoader();
      $translateProvider.forceAsyncReload(true);
      $translateProvider.useLoader('companyTranslationLoader');
      $translateProvider.fallbackLanguage('default');
      $translateProvider.preferredLanguage('default');

      $translateProvider.useSanitizeValueStrategy('sanitize');
        //lock.on('loginSuccess', function ($location, auth, LoginStateFactory) {
        //  $location.path('/');
        //  auth.profilePromise.then(function (profile) {
        //    LoginStateFactory.saveLocalProfile();
        //    //var key = LoginStateFactory.apiLogin();
        //    //console.log(profile);
        //    //key.then(function() {
        //    //  LoginStateFactory.loginUser(profile);
        //    //});
        //  });
        //});



      var resolveTimecardData = function (timecardDataContext, LoginStateFactory, $q) {
        //var loginpromise = LoginStateFactory.apiLogin();
        var dataPromise = $q.defer();
        timecardDataContext.prime().then(function () {
          dataPromise.resolve();
        });
        /*loginpromise.then(function () {
          
        });*/

        return dataPromise.promise;
      };


      //      $urlRouterProvider.otherwise('/404');
      $urlRouterProvider.otherwise(function ($injector) {
        var $state = $injector.get('$state');
        $state.go('home');
      });

      $stateProvider.state('home',
        {
          url: '/',
          templateUrl: 'views/main.html',
          controller: 'MainCtrl',
          data: {
            requiresLogin: true,
            pageTitle: 'Home',
           /* welcomeRule: function(user) {
              var profile = user.getUserProfile();

              if (profile && profile.FirstTimeUsage) {
                var returnObj = {
                  to: 'welcome'
                };

                return returnObj;
              }
            }*/
          },

          resolve: {
            //'fixLoad': function() {
            //  window.loading_screen.finish();
            //}
            'timecardData': function (timecardDataContext, LoginStateFactory, $q) {
              //console.log(LoginStateFactory);
              
              console.log('before login promise');
              var loginpromise = LoginStateFactory.apiLogin();
              console.log('prom', loginpromise);
              console.log('after login promise');
              var dataPromise = $q.defer();
              
              
              loginpromise.then(function (data) {
                console.log('yep he done',data);
                timecardDataContext.prime()
                  .then(function () {
                    window.loading_screen.finish();
                    dataPromise.resolve();
                  });
                console.log('jsdfkljfklsdjfklsdjklfsdkl');
              }).catch(function () { console.log('error doing thingy'); });
              
              return dataPromise.promise;
            }
          }
        })
        .state('error',
        {
          url: '/error',
          templateUrl: 'views/error.html'
        })
        .state('error.404',
        {
          url: '/404',
          templateUrl: 'views/error.404.html'
        })
        .state('about',
        {
          url: '/about',
          templateUrl: 'views/about.html',
          controller: 'AboutCtrl',
          data: { requiresLogin: true }
        })
        .state('preferences',
        {
          url: '/preferences',
          templateUrl: 'views/preferences.html',
          controller: 'PreferenceCtrl',
          data: { requiresLogin: true },
          resolve: {
            'timecardData': function(timecardDataContext, LoginStateFactory, $q) {
              var loginpromise = LoginStateFactory.apiLogin();
              var dataPromise = $q.defer();
              loginpromise.then(function() {
                timecardDataContext.prime()
                  .then(function() {
                    window.loading_screen.finish();
                    dataPromise.resolve();
                  });
              });

              return dataPromise.promise;
            }
          }
        })
        .state('crew-timecard',
        {
          url: '/crew-timecard',
          templateUrl: 'views/crew-timecard.html',
          controller: 'CrewTimecardCtrl',
          data: { requiresLogin: true },
          resolve: {
            'timecardData': function(timecardDataContext, LoginStateFactory, $q) {
              var loginpromise = LoginStateFactory.apiLogin();
              var dataPromise = $q.defer();
              
              loginpromise.then(function() {
                timecardDataContext.prime()
                .then(function () {
                  window.loading_screen.finish();
                  dataPromise.resolve();
                });
              });

              return dataPromise.promise;
            }
          }
        })
        .state('employee-timecard',
        {
          url: '/employee-timecard',
          templateUrl: 'views/employee-timecard.html',
          controller: 'EmployeeTimecardCtrl',
          data: { requiresLogin: true },
          resolve: {
            'timecardData': function(timecardDataContext, LoginStateFactory, $q) {
              var loginpromise = LoginStateFactory.apiLogin();
              var dataPromise = $q.defer();
              
              loginpromise.then(function() {
                timecardDataContext.prime()
                .then(function () {
                  window.loading_screen.finish();
                  dataPromise.resolve();
                });
              });

              return dataPromise.promise;
            }
          }
        })
        .state('review-timecard',
        {
          url: '/review-timecard',
          templateUrl: 'views/review-timecard.html',
          controller: 'ReviewTimecardCtrl',
          data: { requiresLogin: true },
          resolve: {
            'timecardData': function(timecardDataContext, LoginStateFactory, $q) {
              var loginpromise = LoginStateFactory.apiLogin();
              var dataPromise = $q.defer();
              
              loginpromise.then(function () {
              timecardDataContext.prime()
                .then(function () {
                  window.loading_screen.finish();
                  dataPromise.resolve();
                });
              });

              return dataPromise.promise;
            }
          }
        })
        .state('help',
        {
          url: '/help',
          templateUrl: 'views/help.html',
          controller: 'HelpCtrl',
          data: { requiresLogin: true },
          resolve: {
            'hideLoading': function() {
              window.loading_screen.finish();
            }
          }
        })
        .state('login',
        {
          url: '/login',
          templateUrl: 'views/login.html',
          controller: 'LoginCtrl',
          resolve: {
            'hideLoading': function() {
              window.loading_screen.finish();
            }
          }
        })
        .state('logincallback',
        {
          url: '/logincallback',
          templateUrl: 'views/logincallback.html',
          controller: 'LoginCallbackCtrl'
        })
        .state('welcome',
        {
          url: '/welcome',
          templateUrl: 'views/welcome.html',
          controller: 'WelcomeCtrl',
          resolve: {
            'timecardData': function(timecardDataContext, LoginStateFactory, $q) {
              var loginpromise = LoginStateFactory.apiLogin();
              var dataPromise = $q.defer();
              loginpromise.then(function() {
                timecardDataContext.prime()
                  .then(function() {
                    window.loading_screen.finish();
                    dataPromise.resolve();
                  });
              });

              return dataPromise.promise;
            }
          }
        })
        .state('welcome.wizard-company',
        {
          templateUrl: 'views/welcome-company.html'
        })
        .state('welcome.wizard-hqinfo',
        {
          templateUrl: 'views/welcome-headquarters-info.html'
        })
        .state('welcome.wizard-confirmcompany',
        {
          templateUrl: 'views/welcome-confirmcompany.html'
        })
        .state('company',
        {
          url: '/company',
          template: '<div ui-view></div>'
        })
        .state('company.plan',
        {
          url: '/plan',
          templateUrl: 'views/company.plan.html',
          controller: 'CompanyPlanCtrl'
        })
        .state('company.pay',
        {
          url: '/pay/:id',
          controller: 'CompanyPayCtrl',
          templateUrl: 'views/company.pay.html'
        })
        .state('company.job',
        {
          url: '/job',
          templateUrl: 'views/jobSetup.html',
          controller: 'JobSetupCtrl',
          resolve: { 'timecardData': resolveTimecardData }
        })
        .state('company.department',
        {
          url: '/department',
          templateUrl: 'views/departmentSetup.html',
          controller: 'DepartmentSetupCtrl',
          resolve: { 'timecardData': resolveTimecardData }
        })
        .state('company.department.edit',
        {
          url: '/edit/:id',
          templateUrl: 'views/departmentSetup.editor.html',
          controller: [
            '$scope', '$state', '$stateParams', 'timecardDataContext',
            function($scope, $state, $stateParams, timecardDataContext) {
              $scope.editDivision = timecardDataContext.getDivisionById($stateParams.id)[0];
            }
          ]
        })
        .state('company.department.new',
        {
          url: '/new',
          templateUrl: 'views/departmentSetup.new.html',
          controller: [
            '$scope', '$state', 'timecardDataContext', function($scope, $state, timecardDataContext) {
              $scope.newDivision = timecardDataContext.createDivision({ CompanyId: 82, Name: 'New Division' }, true);
              $scope.doSave = function() {
                var manager = timecardDataContext.getTimecardManager();
                manager.addEntity($scope.newDivision);
                timecardDataContext.saveTimecards()
                  .then(function() {
                    $scope.$parent.refreshDivisions();
                    $state.go('^');
                  });
              };
            }
          ]
        })
        .state('lookup',
        {
          url: '/lookup',
          templateUrl: 'views/lookup.html',
          controller: 'LookupCtrl as lookupVm',
          resolve: {
            'timecardData': function (timecardDataContext, LoginStateFactory, $q) {
              var loginpromise = LoginStateFactory.apiLogin();
              var dataPromise = $q.defer();
              loginpromise.then(function () {
                timecardDataContext.prime()
                  .then(function () {
                    window.loading_screen.finish();
                    dataPromise.resolve();
                  });
              });

              return dataPromise.promise;
            }
          }
        }).state('billing-review',
        {
          url: '/billing-review',
          templateUrl: 'views/billing-review.html',
          controller: 'BillingReviewCtrl as billingVm',
          resolve: {
            'timecardData': function (timecardDataContext, LoginStateFactory, $q) {
              var loginpromise = LoginStateFactory.apiLogin();
              var dataPromise = $q.defer();
              loginpromise.then(function () {
                timecardDataContext.prime()
                  .then(function () {
                    window.loading_screen.finish();
                    dataPromise.resolve();
                  });
              });

              return dataPromise.promise;
            }
          }
        });


        angularAuth0Provider.init({
          clientID: ConfigServiceProvider.$get().getAuth0ClientId(),
          domain: ConfigServiceProvider.$get().getAuth0EndpointUrl()

        });

      lockProvider.init({
        clientID: ConfigServiceProvider.$get().getAuth0ClientId(),
        domain: ConfigServiceProvider.$get().getAuth0EndpointUrl(),
        options: {
          autoclose: true,
          auth: {
            //audience: 'http://constructo.int',
            responseType: 'token id_token',
            sso: true
            //params: {
            //  scope: 'openid email name user_metadata app_metadata picture'
            //}
          }
        },
        callbackUrl: location.href
        //loginState: 'login'
        //loginUrl: '/login'
        //,sso: false
      });


    }
  ])
  .run([
    '$log', '$rootScope', '$state', '$translate', '$window', 'AppStateService', 'lock', 'breeze',
    'ConfigService', 'jwtHelper', 'store', 'UserProfileService', 'VersionService', 'NotificationService', 'LoginStateFactory',
    function ($log, $rootScope, $state, $translate, $window, AppStateService, lock, breeze, ConfigService, jwtHelper, store, UserProfileService,
      VersionService, NotificationService, LoginStateFactory) {

      //auth.hookEvents();
      lock.interceptHash();


      //lock.on('authenticated', function ($location, lock, LoginStateFactory) {
      //  $log.info("In the login authenticated event.");
      //  lock.profilePromise.then(function (profile) {
      //    LoginStateFactory.saveLocalProfile();
      //    //var key = LoginStateFactory.apiLogin();
      //    //console.log(profile);
      //    //key.then(function() {
      //    //  LoginStateFactory.loginUser(profile);
      //    //});
      //  });
      //});
      console.log('hey hey lock is here', lock);
      lock.on('authenticated', function (authResult) {
        LoginStateFactory.saveLocalProfile(authResult);
        console.log('gonna do loginuser');
        var key = LoginStateFactory.apiLogin();
        console.log(authResult.idTokenPayload);
        key.then(function() {
          LoginStateFactory.loginUser(authResult.idTokenPayload);
          
        });

        LoginStateFactory.loginUser(authResult.idTokenPayload);
        console.log("auth", authResult);
      });

      lock.on('show',
        function() {
          console.log('login page shown');
        });

      lock.on('hash_parsed',
        function (authResult) {
          console.log('hash parsed', authResult);
        });

      lock.on('authorization_error',
        function (error) {
          console.log('login auth error', error);
        });

      $rootScope.$on('$translateLoadingSuccess', function () {
        //$translate.refresh();
      });
      // This events gets triggered on refresh or URL change
      $rootScope.$on('$locationChangeStart', function (event, next, current) {
        console.log('locationchange', event, next, current);

        if (!lock.isAuthenticated) {
          var token = store.get('token');

          

          if (token) {
            if (!jwtHelper.isTokenExpired(token)) {
              //lock.authenticate(store.get('profile'), token);
            } else {
              // Either show Login page or use the refresh token to get a new idToken
            }
          }
        }
        /*
        VersionService.getServiceVersion().then(function (data) {
          var clientVersion = VersionService.getClientVersion();
          if (clientVersion !== data.version) {
            // Server version != client version
            NotificationService.createNotification({
              id: 'global-update-notification', title: 'Update Available',
              //severity: 'danger',
              message: 'There is a new update for timecard!', icon: 'fa-refresh',
              actions: [
                {
                  icon: 'fa-refresh',
                  text: 'Update and Restart',
                  severity: 'danger',
                  action: function() {
                    $log.debug('Reloading the application...');
                    $window.location.reload(true);
                  }
                }
              ]
            });
          }
        }, function (error) {
          // BOMB OUT!
          throw new Error("Could not load server version!");
        });
        */
      });


      $rootScope.$on('$stateChangeStart',
        function (event, toState, toParams, fromState, fromParams, options) {

          
          console.debug('Changing application state to state:', event);
          $log.debug('Changing application state to state:', toState);
        });

      $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
        if (!toState.data) {
          return;
        }
        if (!angular.isFunction(toState.data.welcomeRule)) {
          return;
        }
        var result = toState.data.welcomeRule(UserProfileService);
        if (result && result.to) {
          $state.go(result.to);
        }
      });
      $log.debug('Preferred Language', ConfigService.getPreferredLanguage());
      $translate.use(ConfigService.getPreferredLanguage());

      //.withTags(AppStateService.getLogTags())
      //$window.Raygun.init(ConfigService.getRaygunApiKey(), { disableAnonymousUserTracking: true }).withTags(AppStateService.getLogTags()).withCustomData(function () {
      //  return {
      //    hash: document.location.hash
      //  };
      //});
      //$window.Raygun.setVersion(AppStateService.getVersion());


      function enhanceLogging(loggingFunc, context, sendToRaygun) {

        return function () {
          var modifiedArguments = [].slice.call(arguments);
          //if (sendToRaygun && ConfigService.useRaygun()) {
          //    try {
          //        throw new Error(modifiedArguments[0]);
          //    } catch (e) {

          //    }
          //}

          modifiedArguments[0] = ['[' + $window.moment().format() + '::' + context + ']  '] + modifiedArguments[0];
          loggingFunc.apply(null, modifiedArguments);
        };
      }

      $log.getInstance = function (context) {

        return {
          log: enhanceLogging($log.log, context, false),
          info: enhanceLogging($log.info, context, false),
          warn: enhanceLogging($log.warn, context, false),
          debug: enhanceLogging($log.debug, context, false),
          error: enhanceLogging($log.error, context, true)
        };
      };
      $log.debug(AppStateService.getVersionString());
      $log.debug('Initialized Breeze');


    }
  ]).controller('AppCtrl', [
    '$location',
    '$log',
    '$scope',
    '$window',
    'AppStateService',
    'lock',
    'LoginStateFactory',
    function ($location, $log, $scope, $window, AppStateService, lock, LoginStateFactory) {
      var executedAuth = false;
      $scope.AppStateService = AppStateService;

      //$scope.$state = $state;
      $scope.$watch(function () { return lock.profile; }, function (authData) {
        if (authData && !executedAuth) {
          //var promise = LoginStateFactory.apiLogin();
          //promise.then(function() {
          //    //LoginStateFactory.loginUser(authData);
          //    //LoginStateFactory.getUserProfile();
          //    executedAuth = true;
          //});

          //LoginCtrl.loginPusher();
          //LoginStateFactory.loginPusher();
        }
      });

      $scope.globalAlerts = AppStateService.applicationAlerts;
    }
  ])
.factory('companyTranslationLoader', ['$http', '$log', '$q', 'ConfigService', function($http, $log, $q, ConfigService) {
  return function (options) {
    var deferred = $q.defer();
    var translationsEndpoint = ConfigService.getMiscApiEndpoint() + '/translations/' + options.key;
    $http.get(translationsEndpoint)
      .then(function (translation) {
        $log.debug('Received translation download');
        console.log(translation.data);
        deferred.resolve(translation.data);
      });

    return deferred.promise;

    //return ;
  };
}])
.factory('$exceptionHandler', [
     '$injector', 'ConfigService', function ($injector, ConfigService) {
       return function (exception, cause) {
         console.log(cause);
         window.loading_screen = window.pleaseWait({
           logo: ConfigService.getBigLogo(),
           backgroundColor: '#1F2322',
           loadingHtml: "<p class='loading-error-message'>There was an error!</p><div class=\"error-message-details\">" +
             "Sorry, something went wrong.<p>A team of highly trained monkeys has been dispatched to deal with this situation." +
             "If you see them, show them this information: <div class=\"error-code\">" + exception + "</div></p><button onClick=\"location.reload(true);\" class=\"btn btn-primary\"><i class=\"fa fa-fw fa-repeat\"></i>&nbsp;Restart Timecard</button></div>"
         });
         //var $window = $injector.get('$window');
         if (ConfigService.useRaygun()) {
           window.Raygun.send(exception);
         }


         //var modal = $injector.get('$modal');

         //var modalInstance = modal.open({
         //  templateUrl: 'views/dialog.html',
         //  controller: 'DialogCtrl',
         //  size: 'lg',
         //  resolve: {
         //    options: function () {
         //      return {
         //        dialogButtons: [
         //            {
         //              buttonAction: function () {
         //                modalInstance.close('Restart Application');
         //                $window.location.href = '/';
         //                $window.location.reload();
         //              },
         //              buttonClass: 'danger',
         //              buttonIcon: 'fa-exclamation-triangle',
         //              buttonText: 'Restart Application'
         //            }
         //        ],
         //        dialogClass: 'danger',
         //        dialogIcon: 'fa-exclamation-triangle',
         //        dialogMessage: 'There was an error with the application! Tech support has been notified, click Restart to reload the application.',
         //        dialogTitle: 'Catastrophic Timecard Error'
         //      };
         //    }
         //  }
         //});


         throw exception;
       };
     }
]);

},{"./controllers":23,"./directives":25,"./filters":53,"./services":66}],2:[function(require,module,exports){
'use strict';

module.exports = function($scope) {
  $scope.awesomeThings = [
    'HTML5 Boilerplate',
    'AngularJS',
    'Karma'
  ];
};

},{}],3:[function(require,module,exports){
'use strict';

module.exports = function() {
  var vm = this;

  var getDateStyle = function(data, mode) {

  };
  vm.datepickerOptions = {
    customClass: getDateStyle,
    minDate: null,
    showWeeks: true
  };

};


},{}],4:[function(require,module,exports){
'use strict';

//app.controller('CompanyPayCtrl', [
//  '$rootScope',
//  '$scope',
//  '$stateParams',
//  'lodash',
module.exports = function ($rootScope, $scope, $stateParams, lodash) {
  var plans = [
  {
    name: 'Free',
    tier: 'free',
    code: 'punch-free',
    price: 0.00
  },
  {
    name: 'Standard',
    interval: 'month',
    tier: 'std',
    code: 'punch-std',
    price: 9.99
  },
  {
    name: 'Standard',
    interval: 'year',
    tier: 'std',
    code: 'punch-std-yr',
    price: 65.99
  },
  {
    name: 'Pro',
    interval: 'month',
    tier: 'pro',
    code: 'punch-pro',
    price: 19.99
  },
  {
    name: 'Pro',
    interval: 'year',
    tier: 'pro',
    code: 'punch-pro-yr',
    price: 65.99
  }
  ];
  $scope.plan = lodash.find(plans, 'code', $stateParams.id);

  console.log(lodash.find(plans, 'code', 'punch-free'));
}
//]);

},{}],5:[function(require,module,exports){
'use strict';

module.exports = function ($scope) {
  $scope.radioModel = 'month';

  $scope.plans = [
    {
      name: 'Free',
      tier: 'free',
      code: 'punch-free',
      price: 0.00
    },
    {
      name: 'Standard',
      interval: 'month',
      tier: 'std',
      code: 'punch-std',
      price: 9.99
    },
    {
      name: 'Standard',
      interval: 'year',
      tier: 'std',
      code: 'punch-std-yr',
      price: 65.99
    },
    {
      name: 'Pro',
      interval: 'month',
      tier: 'pro',
      code: 'punch-pro',
      price: 19.99
    },
    {
      name: 'Pro',
      interval: 'year',
      tier: 'pro',
      code: 'punch-pro-yr',
      price: 65.99
    }
  ];
};

},{}],6:[function(require,module,exports){
'use strict';

/**
 * @ngdoc function
 * @name frontendApp.controller:CrewTimecardCtrl
 * @description
 * # CrewTimecardCtrl
 * Controller of the frontendApp
 */
module.exports =
      function ($interval, $location, $log, $uibModal, $rootScope, $scope, $state, $timeout, $translate, $window, AppStateService, timecardDataContext, UserProfileService, ConfigService) {
      var windowTitle = 'Crew Time Card';
      AppStateService.setTitle(windowTitle);
      $scope.Math = $window.Math;
      $scope.changeSelectionDisabled = false;
      $scope.currentCrew = null;
      $scope.currentTimecard = null;
      $scope.currentTimecardLines = [];
      $scope.individualArea = null;
      $scope.manualEmployees = [];
      $scope.selectedArea = null;
        $scope.selectedCostType = null;

      $scope.breakMinutes = 15;

      $scope.testArray = [];

      $scope.areas = [];



          $scope.testcounter = 20;

      $scope.selectedJob = null;
      $scope.selectedCrew = null;
          //$scope.selectedDate = null;
      $scope.selectedDate = new Date();
    $scope.selectedTemplate = null;
      $scope.selectedFilter = 'Last30Days';

      $scope.displaySelectedDate = function () {
          return $window.moment($scope.selectedDate).format('MM/DD/YYYY');
      };


      $scope.incidentText = function () {
          if ($scope.currentTimecard) {
              return $scope.currentTimecard.IncidentFlag ? 'REPORTED' : 'NONE';
          } else {
              return 'NONE';
          }
      };

          $scope.startCounter = function() {
              $interval(function () { console.log($scope.testcounter--); }, 1000, 0);
          };

      $scope.subsistenceText = function () {
          if ($scope.selectedJob) {
              var subAmount = $scope.selectedJob.SubsistenceAmount * $scope.manualEmployees.length;
              return $scope.timecardSubsistence ? subAmount : 'NONE';
          } else {
              return 'NONE';
          }

      };

      var inDate = new Date();
      inDate.setHours(7);
      inDate.setMinutes(0);

      var outDate = new Date();
      outDate.setHours(15);
      outDate.setMinutes(30);




      $scope.jobFilterFunction = function (element) {
          return function (job) {
              return true;
          };
      };

      $scope.inOne = roundDownNearest15(inDate);
      $scope.rawPunchIn = roundDownNearest15(inDate);
      $scope.outOne = roundDownNearest15(outDate);
      $scope.rawPunchOut = roundDownNearest15(outDate);

      $scope.recalculatePunches = function() {
        if (!$scope.rawPunchIn) {
          return;
        }

        if (!$scope.rawPunchOut) {
          return;
        }

        var inPunch = $window.moment($scope.rawPunchIn);
        var outPunch = $window.moment($scope.rawPunchOut);


        $log.debug('in recalculate punches', inPunch, outPunch);
        if (outPunch.isBefore(inPunch)) {
          $log.debug('Out punch is before in punch');
          outPunch.add(1, 'days');
        }
        $log.debug('after output calcs');
        $scope.inOne = inPunch;
        $scope.outOne = outPunch;

      };


      $scope.timecardSubsistence = false;

      $scope.totalHoursDifference = function () {
        if (!$scope.inOne || !$scope.outOne) {
          return;
        }
        //$log.debug('Start Time', $scope.inOne);
        //$log.debug('Out Time', $scope.outOne);

        var firstTime = $window.moment($scope.inOne);
        var secondTime = $window.moment($scope.outOne);

        var output = $window.math.round(secondTime.diff(firstTime, 'minutes') / 60.0, 2);

        return output;
      };

      $scope.remainingToAllocate = function () {

        var remaining = 0.0;
        try {
          remaining = $scope.totalHoursDifference();

          for (var i = 0; i < $scope.currentTimecardLines.length; i++) {
            if (isNaN($scope.currentTimecardLines[i].totalHours)) continue;
            remaining -= $scope.currentTimecardLines[i].totalHours;
          }
          if ($scope.currentTimecard && $scope.currentTimecard.LunchTaken) {
            remaining -= $scope.lunchMinutes / 60;
          }

          var returnVar = $window.math.round(remaining, 2);
          return returnVar;
        } catch (ex) {
          return remaining;
        }
      };

          $scope.remainingOverZero = function() {
              return $scope.remainingToAllocate() < 0 ? 0 : $scope.remainingToAllocate();
          };

      $scope.timecardTotalRegular = function () {
          var total = 0.0;

          for (var i = 0; i < $scope.currentTimecardLines.length; i++) {
            if (isNaN($scope.currentTimecardLines[i].RegularHours) || $scope.currentTimecardLines[i].RegularHours === null) {
              $log.debug('timecardTotalRegular', $scope.currentTimecardLines[i].RegularHours);
              continue;
            } else {
              total += $scope.currentTimecardLines[i].RegularHours;
            }
          }

          return $window.math.round(total, 2);
      };

      $scope.addArea = function (area) {
          //$scope.startCounter();
          if ($scope.areas.indexOf(area) === -1) {
              $scope.areas.push(area);
          }


      };

      $translate('TEXT_HELP_IMMEDIATE')
          .then(function (value) {

            $scope.helpInformationText = value;
          });
      $rootScope.$on('$translateChangeSuccess',
        function () {
          $translate('TEXT_HELP_IMMEDIATE')
            .then(function (value) {

              $scope.helpInformationText = value;
            });
        });

      $scope.openHelpModal = function () {
        var modalInstance = $uibModal.open({
          templateUrl: "views/dialog.html",
          controller: "DialogCtrl",
          size: "lg",
          resolve: {
            options: function () {
              return {
                dialogButtons: [
                {
                  buttonAction: function () {
                    modalInstance.dismiss("User Hit OK");
                  },
                  buttonClass: "info",
                  buttonIcon: "fa-check",
                  buttonText: "OK"
                }
                ],
                dialogClass: "danger",
                dialogIcon: "fa-life-ring",
                dialogMessage: $scope.helpInformationText,
                dialogTitle: "Help Information"
              };
            }
          }
        });
      };
      $scope.jobs = timecardDataContext.getJobsLocal();
      $scope.templates = timecardDataContext.getTemplatesLocal();
      $scope.last30DayJobs = timecardDataContext.getJobsLast30Days();
      $scope.employees = timecardDataContext.getEmployeesLocal();

      $scope.incrementReview = function () {
          AppStateService.timecardsToReview++;
      };

      $scope.decrementReview = function () {
          AppStateService.timecardsToReview--;
      };

      $scope.clearReview = function () {
          AppStateService.timecardsToReview = 0;
      };

      $scope.buildTimecard = function () {
          $scope.changeSelectionDisabled = true;
          $scope.currentTimecard = timecardDataContext.createTimecard({ Type: 1, CreateDate: Date(), ReadyToPost: false, LunchTaken: true, BreakTaken: true, IncidentFlag: false }, true);
          for (var i = 0; i < $scope.selectedJob.Areas.length; i++) {
              for (var j = 0; j < $scope.selectedJob.Areas[i].CostCodes.length; j++) {
                  var createdTimecardLine = timecardDataContext.createTimecardLine({ CostCode: $scope.selectedJob.Areas[i].CostCodes[j] }, true);
                  $scope.currentTimecardLines.push(createdTimecardLine);

              }
          }

          $scope.timecardSubsistence = $scope.selectedJob.Subsistence;

          $scope.individualArea = $scope.selectedArea;
          $scope.areas.push($scope.selectedArea);
          $scope.currentCrew = $scope.selectedCrew;

          if ($scope.selectedCrew) {
              var testArray = $scope.selectedCrew.EmployeeCrews;
              for (var len = 0; len < testArray.length; len++) {
                  $scope.manualEmployees.push(testArray[len].Employee);
              }
          }


      };

      $scope.filterByArea = function (area) {
          return function (timecardLine) {
              return timecardLine.CostCode.Area === area;
          };
      };

      $scope.filterByAreas = function (element) {
          return element.CostCode.Area === $scope.individualArea;
      };

      $scope.openCrewEditor = function () {
        $uibModal.open({
              templateUrl: 'crew-edit/crew-edit.tpl.html',
              controller: 'CrewEditCtrl',
              size: 'lg',
              scope: $scope,
              backdrop: 'static'
          });
      };


      $scope.saveTemplate = function () {
        var modalInstance = $uibModal.open({
              controller: 'SaveTemplateCtrl',
              size: 'lg',
              backdrop: 'static',
              scope: $scope,
              templateUrl: 'views/savetemplate.html'
          });

          modalInstance.result.then(function() {
              $scope.$apply();
          });
      };

        $scope.hoursOk = function () {
          var sumHours = 0;
          for (var i = 0; i < $scope.currentTimecardLines.length; i++) {
            sumHours += $scope.currentTimecardLines[i].totalHours;
          }


          if ($scope.manualEmployees.length > 0 && sumHours > 0) {
            return true;
          }
          return false;
        };

        $scope.craftClassesOk = function() {
          var returnVal = true;
          console.log($scope.currentTimecardLines);
          for (var i = 0; i < $scope.manualEmployees.length; i++) {
            console.log(returnVal);
            console.log($scope.manualEmployees[i].CraftClass);
            returnVal &= !!$scope.manualEmployees[i].CraftClass;
          }

          return returnVal;
        };

      $scope.loadTemplate = function () {
        $scope.lunchMinutes = '30';

          if (!$scope.selectedTemplate) {
              if ($scope.selectedJob && $scope.selectedArea) {
                  $scope.buildTimecard();
              }
              return;
          }

          console.log($scope.selectedTemplate);
            if ($scope.selectedTemplate.TemplateJson) {
                var templateObject = JSON.parse($scope.selectedTemplate.TemplateJson);

                if (templateObject.JobId) {
                    var job = timecardDataContext.getJobById(templateObject.JobId)[0];
                    $scope.selectedJob = job;

                    for (var i = 0; i < $scope.selectedJob.Areas.length; i++) {
                        if ($scope.selectedJob.Areas[i].Id === templateObject.AreaId) {
                            $scope.selectedArea = $scope.selectedJob.Areas[i];
                        }
                    }
                }

                if (templateObject.EmployeeIds) {
                    for (var j = 0; j < templateObject.EmployeeIds.length; j++) {
                        var employee = timecardDataContext.getEmployeeById(templateObject.EmployeeIds[j])[0];
                        $scope.manualEmployees.push(employee);
                    }
                }
                $scope.buildTimecard();

                if ($scope.selectedTemplate.Timecard) {
                    var tempTimecard = $scope.selectedTemplate.Timecard;
                    console.log('timecard exists');
                    for (var k = 0; k < tempTimecard.TimecardLines.length; k++) {
                        for (var l = 0; l < $scope.currentTimecardLines.length; l++) {
                            if (tempTimecard.TimecardLines[k].CostCodeId === $scope.currentTimecardLines[l].CostCodeId) {
                                $scope.currentTimecardLines[l].RegularHours = tempTimecard.TimecardLines[k].RegularHours;
                                $scope.currentTimecardLines[l].OvertimeHours = tempTimecard.TimecardLines[k].OvertimeHours;
                                $scope.currentTimecardLines[l].DoubletimeHours = tempTimecard.TimecardLines[k].DoubletimeHours;
                                $scope.currentTimecardLines[l].TravelHours = tempTimecard.TimecardLines[k].TravelHours;
                            }
                        }
                    }
                }
            }

        };

      $scope.viewActions = [
      {
        text: 'Build Timecard',
        cssClass: 'btn-green',
        icon: 'fa-refresh',
        action: $scope.loadTemplate
      }
      ];
        var getRowInformation = function(line) {

          var returnValue = {
            'OriginalValues': [
              { 'RegularHours': line.RegularHours },
              { 'OvertimeHours': line.OvertimeHours },
              { 'TravelHours': line.TravelHours },
              { 'SubsistenceAmount': line.SubsistenceAmount },
              { 'Notes': line.Notes }
            ],
            'ChangedFields': []
          };

          return JSON.stringify(returnValue);

        };
        $scope.overtimeOk = function () {
          var remOverZero = $scope.remainingOverZero();
          var remain = $scope.remainingToAllocate();

          return remOverZero === 0 && remain === 0;
        };
      $scope.saveTimecard = function (options) {
          if (!$scope.hoursOk()) {
            // BAD STATE HERE

            var modalInstance = $uibModal.open({
                templateUrl: 'views/dialog.html',
                controller: 'DialogCtrl',
                size: 'lg',
                resolve: {
                  options: function () {
                    return {
                      dialogButtons: [
                          {
                            buttonAction: function () {
                              modalInstance.dismiss('Close');
                            },
                            buttonClass: 'warning',
                            buttonIcon: 'fa-trash-o',
                            buttonText: 'Close'
                          }
                      ],
                      dialogClass: 'danger',
                      dialogIcon: 'fa-exclamation-circle',
                      dialogMessage: 'Please verify employees are added to the timecard and there are more than zero hours of time.',
                      dialogTitle: 'Timecard Validation Error'
                    };
                  }
                }
              });


            return;
          };
          if (!$scope.craftClassesOk()) {

            var modalInstance = $uibModal.open({
              templateUrl: 'views/dialog.html',
              controller: 'DialogCtrl',
              size: 'lg',
              resolve: {
                options: function () {
                  return {
                    dialogButtons: [
                        {
                          buttonAction: function () {
                            modalInstance.dismiss('Close');
                          },
                          buttonClass: 'warning',
                          buttonIcon: 'fa-trash-o',
                          buttonText: 'Close'
                        }
                    ],
                    dialogClass: 'danger',
                    dialogIcon: 'fa-exclamation-circle',
                    dialogMessage: 'Please verify employees all have craft classes set.',
                    dialogTitle: 'Timecard Validation Error'
                  };
                }
              }
            });


            return;
          };
          if (!$scope.overtimeOk()) {
            // BAD STATE HERE

            var modalInstance = $uibModal.open({
              templateUrl: 'views/dialog.html',
              controller: 'DialogCtrl',
              size: 'lg',
              resolve: {
                options: function () {
                  return {
                    dialogButtons: [
                        {
                          buttonAction: function () {
                            modalInstance.dismiss('Close');
                          },
                          buttonClass: 'warning',
                          buttonIcon: 'fa-trash-o',
                          buttonText: 'Close'
                        }
                    ],
                    dialogClass: 'danger',
                    dialogIcon: 'fa-exclamation-circle',
                    dialogMessage: 'In and out times do not match allocated work duration.  Please verify in/out times or time allocation.',
                    dialogTitle: 'Timecard Validation Error'
                  };
                }
              }
            });


            return;
          };
          var currentTc = $scope.currentTimecard;
          var newTimecard = timecardDataContext.createTimecard({
              Type: currentTc.Type,
              CreateDate: currentTc.CreateDate,
              ReadyToPost: currentTc.ReadyToPost,
              LunchTaken: currentTc.LunchTaken,
              BreakTaken: currentTc.BreakTaken,
              IncidentFlag: currentTc.IncidentFlag
          }, false);
          $log.debug('Creating permanent timecard for new timecard.',newTimecard);
          // AppStateService.unpostedTimecards.push(newTimecard);
          for (var j = 0; j < $scope.manualEmployees.length; j++) {
              var crewMember = $scope.manualEmployees[j];

              var createdInOne = timecardDataContext.createTimecardPunch({
                  Employee: crewMember,
                  PunchType: 0,
                  EventDateTime: $scope.inOne
              }, false);

              var createdOutOne = timecardDataContext.createTimecardPunch({
                  Employee: crewMember,
                  PunchType: 1,
                  EventDateTime: $scope.outOne
              }, false);

              for (var i = 0; i < $scope.currentTimecardLines.length; i++) {

                  var line = $scope.currentTimecardLines[i];
                  if (line.totalHours !== 0) {
                      line.Notes = $scope.timecardNote;

                      var createdLine = timecardDataContext.createTimecardLine({
                          Employee: crewMember,
                          CostCode: line.CostCode,
                          RegularHours: line.RegularHours,
                          OvertimeHours: line.OvertimeHours,
                          DoubletimeHours: line.DoubletimeHours,
                          TravelHours: line.TravelHours,
                          Job: $scope.selectedJob,
                          Crew: $scope.selectedCrew,
                          Craft: crewMember.Craft,
                          CraftClass: crewMember.CraftClass,
                          WorkDate: $scope.selectedDate,
                          Notes: line.Notes,
                          CostTypeId: $scope.selectedCostType.Id,
                          Division: $scope.selectedJob.Division,
                          LineType: 0,
                          RowInformation: getRowInformation(line)
                          //Subsistence: $scope.timecardSubsistence
                      }, false);
                      newTimecard.TimecardLines.push(createdLine);
                      AppStateService.timecardsToReview++;

                      }
              }
              if ($scope.timecardSubsistence) {
                  var subsistenceCreatedLine = timecardDataContext.createTimecardLine({
                      Employee: crewMember,
                      CostCode: $scope.selectedJob.SubsistenceCostCode,
                      RegularHours: 0.00,
                      OvertimeHours: 0.00,
                      DoubletimeHours: 0.00,
                      TravelHours: 0.00,
                      Job: $scope.selectedJob,
                      Crew: $scope.selectedCrew,
                      Craft: crewMember.Craft,
                      CraftClass: crewMember.CraftClass,
                      WorkDate: $scope.selectedDate,
                      CostTypeId: $scope.selectedCostType.Id,
                      Division: $scope.selectedJob.Division,
                      LineType: 1,
                      SubsistenceAmount: $scope.selectedJob.SubsistenceAmount,
                      RowInformation: getRowInformation(line)
                  }, false);
                  AppStateService.timecardsToReview++;
                  newTimecard.TimecardLines.push(subsistenceCreatedLine);


              }
              newTimecard.TimecardPunches.push(createdInOne);
              //newTimecard.TimecardPunches.push(createdInTwo);
              newTimecard.TimecardPunches.push(createdOutOne);
              //newTimecard.TimecardPunches.push(createdOutTwo);
          }

          timecardDataContext.saveReviewDataLocally();

          $scope.areas = [];
          $scope.selectedCrew = null;
          $scope.currentCrew = null;
          $scope.currentTimecard = null;
          $scope.currentTimecardLines = [];
          $scope.individualArea = null;
          $scope.changeSelectionDisabled = false;
          $scope.manualEmployees = [];
          $scope.selectedEmployee = null;
          if (options.review) {
              $state.go('review-timecard');
             // $location.url('/review-timecard');
          }
      };

      $scope.resetCurrentArea = function () {
        $window.console.log('resetting area.');
          for (var i = 0; i < $scope.currentTimecardLines.length; i++) {
              var line = $scope.currentTimecardLines[i];
              if (line.CostCode.Area === $scope.individualArea) {
                  line.RegularHours = 0;
                  line.OvertimeHours = 0;
                  line.DoubletimeHours = 0;
                  line.TravelHours = 0;
              }
          }
      };

      $scope.resetTimecard = function () {
        var modalInstance = $uibModal.open({
              templateUrl: 'views/dialog.html',
              controller: 'DialogCtrl',
              size: 'lg',
              resolve: {
                  options: function () {
                      return {
                          dialogButtons: [
                              {
                                  buttonAction: function () {
                                      modalInstance.close('Reset');
                                  },
                                  buttonClass: 'warning',
                                  buttonIcon: 'fa-trash-o',
                                  buttonText: 'Reset'
                              },
                              {
                                  buttonAction: function () {
                                      modalInstance.dismiss('User Cancelled');
                                  },
                                  buttonClass: 'danger',
                                  buttonIcon: 'fa-times',
                                  buttonText: 'Cancel'
                              }
                          ],
                          dialogClass: 'warning',
                          dialogIcon: 'fa-question-circle',
                          dialogMessage: 'Are you sure you wish to reset the current timecard? You will lose all of your work.',
                          dialogTitle: 'Reset Timecard'
                      };
                  }
              }
          });

          modalInstance.result.then(function (resultString) {
              $scope.areas = [];
              $scope.selectedArea = null;
              $scope.selectedCrew = null;
              $scope.manualEmployees = [];
              $scope.selectedJob = null;
              $scope.currentCrew = null;
              $scope.currentTimecard = null;
              $scope.currentTimecardLines = [];
              $scope.individualArea = null;
              $scope.changeSelectionDisabled = false;
              $scope.selectedTemplate = null;
          }, function (resultString) {
          });
      };

      $scope.enterHoursBox = function (event) {

        var target = angular.element(event.target);
        if (target.val() == 0) {
          target.val('');
        }
      };

      $scope.exitHoursBox = function (timecardLine) {
        if (!timecardLine) {
          return;
        }

        if (timecardLine.RegularHours === '' || isNaN(timecardLine.RegularHours)) {
          timecardLine.RegularHours = 0.0;
        }
        if (timecardLine.OvertimeHours === '' || isNaN(timecardLine.OvertimeHours)) {
          timecardLine.OvertimeHours = 0.0;
        }
        if (timecardLine.DoubletimeHours === '' || isNaN(timecardLine.DoubletimeHours)) {
          timecardLine.DoubletimeHours = 0.0;
        }
        if (timecardLine.TravelHours === '' || isNaN(timecardLine.TravelHours)) {
          timecardLine.TravelHours = 0.0;
        }
      };

      $scope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
          if (toState.resolve) {

          }
      });
      $scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
          if (toState.resolve) {

          }
      });

      $scope.$on('$viewContentLoaded', function (event) {
          $window.ga('send', 'pageview', { page: $location.path(), title: windowTitle });
      });

      /* HELPER FUNCTIONS FOR DATE PICKER */

      $scope.today = function () {
         $scope.selectedDate = new Date();
      };
      //$scope.today();

      $scope.clear = function () {
          $scope.selectedDate = null;
      };

      // Disable weekend selection
      $scope.disabled = function (date, mode) {
          //return (mode === 'day' && (date.getDay() === 0 || date.getDay() === 6));
          return false;
      };

      $scope.toggleMin = function () {
          var today = new Date(); //Today's Date
          $scope.minDate = $scope.minDate ? null : new Date(today.getFullYear(), today.getMonth()-1, today.getDate());
      };
      $scope.toggleMin();

      $scope.open = function ($event) {
          $event.preventDefault();
          $event.stopPropagation();

          $scope.opened = true;
      };

      $scope.dateOptions = {
          formatYear: 'yy',
          startingDay: 1
      };

      $scope.formats = ['MM/dd/yy'];
      $scope.format = $scope.formats[0];


      //// LOCAL FUNCTIONS ////
      function roundDownNearest15(date) {
          var coeff = 1000 * 60 * 15;
          var rounded = new Date(Math.round(date.getTime() / coeff) * coeff);

          return rounded;
      }
//      }])

//.
//directive('datepickerPopup',
//  [
//    'datepickerPopupConfig', 'dateParser', 'dateFilter', function(datepickerPopupConfig, dateParser, dateFilter) {
//      return {
//        'restrict': 'A',
//        'require': '^ngModel',
//        'link': function($scope, element, attrs, ngModel) {
//          var dateFormat;

//          //*** Temp fix for Angular 1.3 support [#2659](https://github.com/angular-ui/bootstrap/issues/2659)
//          attrs.$observe('datepickerPopup',
//            function(value) {
//              dateFormat = value || datepickerPopupConfig.datepickerPopup;
//              ngModel.$render();
//            });

//          ngModel.$formatters.push(function(value) {
//            return ngModel.$isEmpty(value) ? value : dateFilter(value, dateFormat);
//          });
//        }
//      };
//    }
//  ])
//  .directive('smallHeader',
//  [
//    '$window', function($window) {
//      return function(scope, elm, attr) {
//        angular.element(window)
//          .bind('scroll',
//            function() {
//              if (angular.element('.navbar').offset().top > 150) {
//                angular.element('nav.navbar-punch').removeClass('hide');
//              } else {
//                angular.element('nav.navbar-punch').addClass('hide');
//              }
//            });
//      };

//    }
};
//  ]);

},{}],7:[function(require,module,exports){
'use strict';

//var app = angular.module('frontendApp');
//app.controller('DepartmentSetupCtrl', [
//  '$scope',
//  'timecardDataContext',
module.exports = function ($scope, timecardDataContext) {
  $scope.divisions = [];

  timecardDataContext.getDivisions()
    .then(function(data) {
      $scope.divisions = data;
    });

  $scope.refreshDivisions = function() {
    timecardDataContext.getDivisions()
      .then(function(data) {
        $scope.divisions = data;
      });
  }
};
//]);

},{}],8:[function(require,module,exports){
'use strict';

/**
 * @ngdoc function
 * @name frontendApp.controller:DialogCtrl
 * @description
 * # DialogCtrl
 * Controller of the frontendApp
 */
//angular.module('frontendApp')
//  .controller('DialogCtrl', ['$uibModal', '$scope', 'options',
module.exports = function ($uibModal, $scope, options) {

      $scope.dialogButtons = options.dialogButtons;
      $scope.dialogClass = "panel-" + options.dialogClass;
      $scope.dialogIcon = options.dialogIcon;
      $scope.dialogMessage = options.dialogMessage;
      $scope.dialogTitle = options.dialogTitle;

    };
  //]);

},{}],9:[function(require,module,exports){
'use strict';

/**
 * @ngdoc function
 * @name frontendApp.controller:CrewTimecardCtrl
 * @description
 * # CrewTimecardCtrl
 * Controller of the frontendApp
 */
//angular.module('frontendApp')
//  .controller('EmployeeTimecardCtrl', ['$uibModal', '$rootScope', '$scope', '$state', 'timecardDataContext', 'UserProfileService',
module.exports = function ($uibModal, $rootScope, $scope, $state, timecardDataContext, UserProfileService, TimecardService) {
  var manager = timecardDataContext.getTimecardManager();
  var saveOverriden;
  $scope.jobIds = UserProfileService.getRecentJobIds();

  $scope.timecardLines = [];

  $scope.clearTimesheet = function() {
    $scope.timecardLines = [];
  };

  $scope.deleteRow = function(line) {
    console.log('deleting line', line);
    var index = $scope.timecardLines.indexOf(line);
    $scope.timecardLines.splice(index, 1);
  };

  $scope.getCategoryDescription = function (category) {
    if (!category) {
      return "No Category";
    }
    console.log('getting category stuff', category);

    var catName = TimecardService.getCategoryDescriptionById(category);
    console.log("got name", catName);

    return catName;
  };

  $scope.saveReview = function() {
    var timecard = timecardDataContext.createTimecard({
      Type: 0,
      CreateDate: new Date(),
      LastModifyDate: new Date(),
      ExcludeFromExport: false,
      ReadyToPost: true,
      Posted: false,
      PostedDateTime: new Date(),
      LunchTaken: false,
      BreakTaken: false,
      IncidentFlag: false,
      ImportedFlag: false,
      ReceiptSent: false
    });
    manager.addEntity(timecard);
    for (var i = 0; i < $scope.timecardLines.length; i++) {
      $scope.timecardLines[i].Timecard = timecard;

      manager.addEntity($scope.timecardLines[i]);
    }

    timecardDataContext.saveReviewDataLocally();

    $scope.timecardLines = [];
    $state.go('review-timecard');
  };

  $scope.$on('$stateChangeStart',
    function(event, toState, toParams, fromState, fromParams) {
      if (!saveOverriden && $scope.timecardLines && $scope.timecardLines.length > 0) {
        event.preventDefault();
        var modalInstance = $uibModal.open({
          templateUrl: 'views/dialog.html',
          controller: 'DialogCtrl',
          size: 'lg',
          resolve: {
            options: function() {
              return {
                dialogButtons: [
                  {
                    buttonAction: function() {
                      modalInstance.close('Exit');
                    },
                    buttonClass: 'warning',
                    buttonIcon: 'fa-trash-o',
                    buttonText: 'Exit'
                  },
                  {
                    buttonAction: function() {
                      modalInstance.dismiss('User Cancelled');
                    },
                    buttonClass: 'danger',
                    buttonIcon: 'fa-times',
                    buttonText: 'Cancel'
                  }
                ],
                dialogClass: 'danger',
                dialogIcon: 'fa-question-circle',
                dialogMessage: 'Are you sure you wish to switch pages without saving? You will lose all of your work.',
                dialogTitle: 'Leave without save?'
              };
            }
          }
        });

        modalInstance.result.then(function(result) {
          if (result === "Exit") {
            saveOverriden = true;
            $state.go(toState);
          }
        });
      }
    });
};
//}]);

},{}],10:[function(require,module,exports){
'use strict';

//angular.module('frontendApp')
//    .controller('HelpCtrl', ['$scope',

module.exports = function($scope) {

};
    //]);

},{}],11:[function(require,module,exports){
'use strict';

//var app = angular.module('frontendApp');
//app.controller('JobSetupCtrl', [
//  '$scope',
//  'timecardDataContext',
module.exports = function ($scope, timecardDataContext) {
  $scope.jobs = [];

  $scope.itemsPerPage = 15;
  $scope.currentPage = 1;

  timecardDataContext.getJobs($scope.currentPage - 1, $scope.itemsPerPage)
    .then(function(data) {
      $scope.jobs = data;
    });

  $scope.refreshJobs = function() {
    timecardDataContext.getJobs($scope.currentPage - 1, $scope.itemsPerPage)
      .then(function(data) {
        $scope.jobs = data;
      });
  }
};
//]);

},{}],12:[function(require,module,exports){
'use strict';

//var app = angular.module('frontendApp');

//app.controller('LoginCallbackCtrl', ['$window',
module.exports = function($window) {

};
//]);

},{}],13:[function(require,module,exports){
'use strict';

/**
 * @ngdoc function
 * @name frontendApp.controller:LoginCtrl
 * @description
 * # LoginCtrl
 * Controller of the frontendApp
 */
//angular.module('frontendApp')

//.controller('LoginCtrl', ['$location', '$log', '$scope', '$state', '$window', 'auth',
//    'AppStateService','ConfigService','LoginStateFactory','store',
module.exports = function ($location, $log, $scope, $state, $window, lock, AppStateService, ConfigService, LoginStateFactory, store) {
  $scope.auth = lock;
  $scope.loginState = LoginStateFactory;
  $scope.login = function () {
    lock.show({
      allowedConnections: ConfigService.getAuth0Scopes(),
      auth: {
        params: {
          scope: 'openid name email role user_id dn'
        }
      }
      });

      $log.debug('User clicked the login button');
  };

  $scope.link = function () {
    lock.signin({
          popup: true,
          access_token: auth.accessToken
      }).then(function () {
          $log.debug('linked');
      }, function () {
      });
  };

  $scope.apiBearerToken = null;

  $scope.logout = function () {
    lock.logout(); 
      $state.go('login');
      store.remove('profile');
    store.remove('token');
    store.remove('expires_at');
    store.remove('auth0.ssodata');
    store.remove('api-token');
    $window.location.reload();
      //$location.url('/login');
      if (LoginStateFactory.presenceChannel) {
          LoginStateFactory.presenceChannel.unsubscribe(ConfigService.getPusherPrivateChannel());
          LoginStateFactory.presenceChannel.unsubscribe(ConfigService.getPusherPresenceChannel());
      }
  };
};
//]);

},{}],14:[function(require,module,exports){
'use strict';
module.exports = function ($window) {
  var vm = this;
  vm.startDate = $window.moment().subtract(1, 'months').startOf('week').format('YYYY-MM-DD');
  vm.endDate = $window.moment().endOf('week').format('YYYY-MM-DD');
  vm.lookupType = 'self';
};

},{}],15:[function(require,module,exports){
'use strict';

/**
 * @ngdoc function
 * @name frontendApp.controller:MainCtrl
 * @description
 * # MainCtrl
 * Controller of the frontendApp
 */
//angular.module('frontendApp')
//  .controller('MainCtrl', ['$scope', '$translate', '$window','AppStateService',
module.exports = function ($scope, $translate, $window, AppStateService) {
  //$scope.loggedInEmployee = timecardDataContext.getEmployeeById(loginStateFactory.getUserProfile().EmployeeId)[0];

  $scope.pageTitle = 'Home';

  $scope.changeLanguage = function(key) {
    $translate.use(key);
  };

  $scope.incrementReview = function() {
    AppStateService.setTimecardsToReview(AppStateService.getTimecardsToReview() + 1);
  };

  $scope.causeError = function() {
    throw new Error('shit something bad happened');
  };

  $scope.viewActions = [
    {
      text: 'Build Timecard',
      cssClass: 'btn-green',
      icon: 'fa-refresh',
      action: function() {
        console.log('test button was clicked');
      }
    }
  ];
};
//]);

},{}],16:[function(require,module,exports){
'use strict';

/**
 * @ngdoc function
 * @name frontendApp.controller:NavctrlCtrl
 * @description
 * # NavctrlCtrl
 * Controller of the frontendApp
 */
module.exports = function ($location, $uibModal, $rootScope,$scope, $translate, AppStateService) {
    var timecardsReviewDisplay = 55;

    $scope.isActive = function (viewLocation) {
      return viewLocation === $location.path();
    };

    $scope.AppStateService = AppStateService;


    $scope.$watch(function() {
      return AppStateService.getTimecardsToReview();
    }, function(newValue, oldValue) {
      if (newValue !== oldValue) {
        timecardsReviewDisplay = newValue;
      }
    });

    $translate('TEXT_HELP_IMMEDIATE')
    .then(function (value) {

      $scope.helpInformationText = value;
    });
    $rootScope.$on('$translateChangeSuccess',
      function() {
        $translate('TEXT_HELP_IMMEDIATE')
          .then(function(value) {
            $scope.helpInformationText = value;
          });
      });

    $scope.openHelpModal = function() {
      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: 'views/dialog.html',
        controller: 'DialogCtrl',
        size: 'lg',
        resolve: {
          options: function() {
            return {
              dialogButtons: [
                {
                  buttonAction: function() {
                    modalInstance.dismiss('User Hit OK');
                  },
                  buttonClass: 'info',
                  buttonIcon: 'fa-check',
                  buttonText: 'OK'
                }
              ],
              dialogClass: 'danger',
              dialogIcon: 'fa-life-ring',
              dialogMessage: $scope.helpInformationText,
              dialogTitle: 'Help Information'
            };
          }
        }
      });
    };

    $scope.navbar = [
      { title: 'Home', state: 'home', icon: 'fa-home', translation: 'HOME_NAV' },
      { title: 'Field Timecard', state: 'crew-timecard', icon: 'fa-road', translation: 'CREW_TIMECARD_NAV' },
      { title: 'Employee Timecard', state: 'employee-timecard', icon: 'fa-clock-o', translation: 'OFFICE_TIMECARD_NAV' },
      {
        title: 'Review', state: 'review-timecard', icon: 'fa-edit', translation: 'REVIEW_TIMECARD_NAV',
        indicator:{
          value: timecardsReviewDisplay,
          indicatorClass: 'label-primary'
        }

      },
      //{ title: 'Reports', state: 'reports', icon: 'fa-area-chart' },
      //{
      //  title: 'Company Setup', icon: 'fa-building', children: [
      //    { title: 'Defaults', state: 'company-default-setup', icon: 'fa-wrench' },
      //    { title: 'Departments', state: 'company-department-setup', icon: 'fa-university' },
      //    { title: 'Jobs', state: 'company-job-setup', icon: 'fa-road' },
      //    { title: 'Cost Codes', state: 'company-costcode-setup', icon: 'fa-dollar' },
      //    { title: 'Users', state: 'company-user-setup', icon: 'fa-user' },
      //    { title: 'Employees', state: 'company-employee-setup', icon: 'fa-group' }
      //  ]
      //},
      { title: 'Preferences', state: 'settings', icon: 'fa-gear', translation: 'PREFERENCES_NAV' },
      { title: 'Help', state: 'help', icon: 'fa-life-ring', translation: 'HELP_NAV' }
    ];


 };

},{}],17:[function(require,module,exports){
'use strict';

module.exports = function($scope, NotificationService) {
  $scope.notifications = NotificationService.getNotifications();

  $scope.removeNotification = function(notification) {
    NotificationService.clearNotification(notification);
  };
};


},{}],18:[function(require,module,exports){
'use strict';

/**
 * @ngdoc function
 * @name frontendApp.controller:OfficeTimecardCtrl
 * @description
 * # OfficeTimecardCtrl
 * Controller of the frontendApp
 */
//angular.module('frontendApp')
//  .controller('OfficeTimecardCtrl',
module.exports = function($scope) {

};
  //);

},{}],19:[function(require,module,exports){
'use strict';

//var app = angular.module('frontendApp');

//app.controller('PreferenceCtrl', [
//  '$http',
//  '$uibModal',
//  '$scope',
//  'ConfigService',
//  'timecardDataContext',
module.exports = function ($http, $uibModal, $scope, ConfigService, timecardDataContext) {
  timecardDataContext.getCompanies()
    .then(function(data) {
        console.log(data);
        $scope.companies = data.results;
      }
    );

  $scope.inviteToCompany = function(company, email) {
    var postData = {
      company: company.Id,
      email: email
    };
    var result = $http.post(ConfigService.getApiRoot() + '/api/user/invite', postData)
      .then(function(data) {
        console.log('returned from inviter');
        console.log(data);
        var modalInstance = $uibModal.open({
          templateUrl: 'views/dialog.html',
          controller: 'DialogCtrl',
          size: 'lg',
          resolve: {
            options: function() {
              return {
                dialogButtons: [
                  {
                    buttonAction: function() {
                      modalInstance.dismiss('User Hit OK');

                    },
                    buttonClass: 'info',
                    buttonIcon: 'fa-check',
                    buttonText: 'OK'
                  }
                ],
                dialogClass: 'success',
                dialogIcon: 'fa-check-square',
                dialogMessage: 'The user has been invited successfully!',
                dialogTitle: 'Invite Processed'
              };
            }
          }
        });
      });

  };
};
//]);

},{}],20:[function(require,module,exports){
'use strict';

/**
* @ngdoc function
* @name frontendApp.controller:ReviewTimecardCtrl
* @description
* # ReviewTimecardCtrl
* Controller of the frontendApp
*/
module.exports = function($uibModal, $log, $scope, $state, $window, $location, AppStateService, timecardDataContext) {
  var windowTitle = 'Review Time Card Data';

  AppStateService.setTitle(windowTitle);

  $scope.allTimecardLines = [];
  $scope.deletedTimecardLines = [];
  $scope.rowBeingEdited = null;


  $scope.deleteTimecardLine = function(row) {
    $log.debug('Marking row as deleted.', row);
    row.entity.entityAspect.setDeleted();
    AppStateService.timecardsToReview--;
    timecardDataContext.saveReviewDataLocally();
  };

  $scope.$on('ngGridEventEndCellEdit',
    function() {
      timecardDataContext.saveReviewDataLocally();
    });

  $scope.timecardGridOptions = {
    data: 'allTimecardLines',
    columnDefs: 'timecardColumnDefs',
    showFilter: true,
    showGroupPanel: true,
    enableColumnResize: true,
    enableCellSelection: true,
    enableRowSelection: false,
    enableCellEditOnFocus: true,
    enableCellEdit: true,
    //groups: ['Employee.fullName', 'CostCode.Area.Job.Name', 'WorkDate'],
    groups: ['Employee.fullName'],
    groupsCollapsedByDefault: false,
    rowTemplate:
      '<div ng-style="{ \'cursor\': row.cursor }" ng-repeat="col in renderedColumns" ng-class="col.colIndex()" class="ngCell {{col.cellClass}}"><div class="ngVerticalBar" ng-style="{height: rowHeight}" ng-class="{ ngVerticalBarVisible: !$last }">&nbsp;</div><div ng-cell></div></div'
  };
  $scope.punchGridOptions = {
    data: 'timecard.TimecardPunches',
    columnDefs: 'punchColumnDefs',
    showFilter: true,
    showGroupPanel: true,
    enableColumnResize: true
  };

  $scope.resizer = function() {
    $window.$(window).trigger('resize');
  };

  $scope.getHoursCount = function() {
    return $scope.allTimecardLines.filter(function(element) { return element.LineType == '0'; }).length;
  };

  $scope.getSubsistenceCount = function() {
    return $scope.allTimecardLines.filter(function(element) { return element.LineType == '1'; }).length;
  };

  $scope.getDeletedHoursCount = function() {
    return $scope.deletedTimecardLines.filter(function(element) { return element.LineType == '0'; }).length;
  };

  $scope.getDeletedSubsistenceCount = function() {
    return $scope.deletedTimecardLines.filter(function(element) { return element.LineType == '1'; }).length;
  };

  $scope.deleteRow = function(row) {
    for (var i = 0; i < $scope.allTimecardLines.length; i++) {
      if (row === $scope.allTimecardLines[i].Id) {
        $scope.deletedTimecardLines.push($scope.allTimecardLines[i]);
        $scope.allTimecardLines.splice(i, 1);

        timecardDataContext.setTimecardLineDeleted(row);
        timecardDataContext.saveReviewDataLocally();
      }
    }
  };
  $scope.editRow = function(row) {
    if ($scope.rowBeingEdited === null) {
      $scope.rowBeingEdited = row;
    }
  };

  $scope.getHoursRowTemplate = function(row, deletedRow) {
    if (row === $scope.rowBeingEdited) {
      return 'hoursEdit';
    }

    if (deletedRow) {
      return 'undeleteHoursDisplay';
    } else {
      return 'hoursDisplay';
    }
  };

  $scope.getNotesRowTemplate = function(row, deletedRow) {
    if (row === $scope.rowBeingEdited) {
      return 'notesEdit';
    } else {
      return 'notesDisplay';
    }
  };

  $scope.getSubsistenceRowTemplate = function(row, deletedRow) {
    if (row === $scope.rowBeingEdited) {
      return 'subsistenceEdit';
    }
    if (deletedRow) {
      return 'undeleteSubsistenceDisplay';
    } else {
      return 'subsistenceDisplay';
    }
  };

  $scope.saveRow = function(row) {
    if (row === $scope.rowBeingEdited) {
      $scope.rowBeingEdited = null;
    }
  };

  $scope.restoreRow = function(row) {
    for (var i = 0; i < $scope.deletedTimecardLines.length; i++) {
      if (row === $scope.deletedTimecardLines[i].Id) {
        $scope.allTimecardLines.push($scope.deletedTimecardLines[i]);
        $scope.deletedTimecardLines.splice(i, 1);
        timecardDataContext.setTimecardLineRestored(row);
        timecardDataContext.saveReviewDataLocally();
      }
    }
  };

  $scope.trackRow = function(line) {


  };

  $scope.saveAll = function() {
    $log.debug('Saving timecard lines to server.');

    for (var i = 0; i < $scope.timecards.length; i++) {
      var errors = $scope.timecards[i].entityAspect.getValidationErrors();
      if (!errors) continue;
      if (errors.length > 0) {
        $log.error('Validation errors encountered', errors);
      }
    }

    var promise = timecardDataContext.saveTimecards();

    promise.then(function() {
        $log.info('Timecard save successful');


        timecardDataContext.removeLocalData();
        $scope.timecards = timecardDataContext.getTimecardsToPost();
        AppStateService.setTimecardsToReview(0);
        $log.debug('Calling breeze manager save.');
        var modalInstance = $uibModal.open({
          templateUrl: 'views/dialog.html',
          controller: 'DialogCtrl',
          size: 'lg',
          resolve: {
            options: function() {
              return {
                dialogButtons: [
                  {
                    buttonAction: function() {
                      modalInstance.dismiss('User Hit OK');
                    },
                    buttonClass: 'info',
                    buttonIcon: 'fa-check',
                    buttonText: 'OK'
                  }
                ],
                dialogClass: 'success',
                dialogIcon: 'fa-check-square',
                dialogMessage: 'The timecard lines have been submitted successfully!',
                dialogTitle: 'Data Posted'
              };
            }
          }
        });

      },
      function(reason) {
        $log.error('Timecard save error!', reason);
        throw new Error('Timecard save error!');
      });
    $scope.allTimecardLines = [];
  };

  $scope.showDataGrid = function() {
    return $scope.allTimecardLines.length > 0 || $scope.deletedTimecardLines.length > 0;
  };

  $scope.setNoteRowHidden = function(line) {
    return !(line.Id === $scope.rowBeingEdited) && !line.Notes;
  };

  var showDeletedHoursFlag = false;

  $scope.toggleDeletedHoursGrid = function() {
    showDeletedHoursFlag = !showDeletedHoursFlag;
  };

  $scope.showDeletedHoursGrid = function() {
    return showDeletedHoursFlag;
  };

  var showDeletedSubsistenceFlag = false;

  $scope.toggleDeletedSubsistenceGrid = function() {
    showDeletedSubsistenceFlag = !showDeletedSubsistenceFlag;
  };

  $scope.showDeletedSubsistenceGrid = function() {
    return showDeletedSubsistenceFlag;
  };

  $scope.isColumnChanged = function(line, column) {
    return line.parsedRowInformation.ChangedFields.indexOf(column) >= 0;
  };

  $scope.changedColumn = function(line, column) {

    if (line.parsedRowInformation.ChangedFields.indexOf(column) >= 0) {

    } else {
      var tempObject = line.parsedRowInformation;
      tempObject.ChangedFields.push(column);

      line.RowInformation = JSON.stringify(tempObject);
    }
  };


  $scope.clearLocalData = function() {
    var modalInstance = $uibModal.open({
      templateUrl: 'views/dialog.html',
      controller: 'DialogCtrl',
      size: 'lg',
      resolve: {
        options: function() {
          return {
            dialogButtons: [
              {
                buttonAction: function() {
                  modalInstance.close('Reset');
                },
                buttonClass: 'warning',
                buttonIcon: 'fa-trash-o',
                buttonText: 'Reset'
              },
              {
                buttonAction: function() {
                  modalInstance.dismiss('User Cancelled');
                },
                buttonClass: 'danger',
                buttonIcon: 'fa-times',
                buttonText: 'Cancel'
              }
            ],
            dialogClass: 'danger',
            dialogIcon: 'fa-question-circle',
            dialogMessage: 'Are you sure you wish to reset the current timecard? You will lose all of your work.',
            dialogTitle: 'Reset Timecard'
          };
        }
      }
    });

    modalInstance.result.then(function(result) {
      if (result === "Reset") {
        timecardDataContext.clearLocalData();
        timecardDataContext.deleteLocalTimecards();
        $scope.timecards = [];
        $scope.timecardLines = [];
        $scope.allTimecardLines = [];
        $scope.deletedTimecardLines = [];
      }
    });
    //$state.go($state.current, {}, { reload: true });
  };


  $scope.$on('$viewContentLoaded',
    function(event) {
      $window.ga('send', 'pageview', { page: $location.path(), title: windowTitle });
      var timecardsToPost = timecardDataContext.getTimecardsToPost();
      var allTimecardLines = timecardDataContext.getTimecardLinesToPost();

      //$scope.allTimecardLines = allTimecardLines;

      for (var i = 0; i < allTimecardLines.length; i++) {
        if (allTimecardLines[i].DeletedFlag === false) {
          $scope.allTimecardLines.push(allTimecardLines[i]);
        } else {
          $scope.deletedTimecardLines.push(allTimecardLines[i]);
        }

      }

      if (timecardsToPost.length > 0) {

        $scope.timecards = timecardsToPost;
        $scope.timecardlines = $scope.timecards[0].TimecardLines;
      } else {
        $scope.timecards = [];
      }
      $log.debug('Timecard lines loaded', allTimecardLines);
    });
};

},{}],21:[function(require,module,exports){
'use strict';

/**
 * @ngdoc function
 * @name frontendApp.controller:SavetemplateCtrl
 * @description
 * # SavetemplateCtrl
 * Controller of the frontendApp
 */
//angular.module('frontendApp')
//  .controller('SaveTemplateCtrl',
//  [
//    '$rootScope', '$scope', '$window', 'timecardDataContext', 'UserProfileService',
module.exports = function ($rootScope, $scope, $window, timecardDataContext, UserProfileService) {
      $scope.templateDescription = null;
      $scope.templateName = null;
      $scope.saveJob = false;
      $scope.saveEmployees = false;
      $scope.saveHours = false;

      $scope.saveTemplateItem = function() {
        var templateInformation = {
          EmployeeIds: []
        };

        var template = timecardDataContext.createTemplate({}, false);

        var timecardTemplate = timecardDataContext.createTimecard({
            CreateDate: Date(),
            LastModifyDate: Date(),
            ExcludeFromExport: true,
            Note: $scope.templateDescription,
            Type: 0
          },
          false);

        template.Timecard = timecardTemplate;
        template.Name = $scope.templateName;
        template.Description = $scope.templateDescription;

        if ($scope.saveJob) {
          templateInformation.JobId = $scope.selectedJob.Id;
          templateInformation.AreaId = $scope.selectedArea.Id;
        }

        if ($scope.saveEmployees) {
          for (var i = 0; i < $scope.manualEmployees.length; i++) {
            templateInformation.EmployeeIds.push($scope.manualEmployees[i].Id);
          }
        }

        if ($scope.saveHours) {
          for (var j = 0; j < $scope.manualEmployees.length; j++) {
            var crewMember = $scope.manualEmployees[j];

            for (var i = 0; i < $scope.currentTimecardLines.length; i++) {

              var line = $scope.currentTimecardLines[i];
              if (line.totalHours !== 0) {
                line.Notes = $scope.timecardNote;
                //newTimecard.TimecardLines.push(line);


                var createdLine = timecardDataContext.createTimecardLine({
                    Employee: crewMember,
                    CostCode: line.CostCode,
                    RegularHours: line.RegularHours,
                    OvertimeHours: line.OvertimeHours,
                    DoubletimeHour: line.DoubletimeHours,
                    TravelHours: line.TravelHours,
                    Job: $scope.selectedJob,
                    Crew: $scope.selectedCrew,
                    Craft: crewMember.Craft,
                    CraftClass: crewMember.CraftClass,
                    WorkDate: $scope.selectedDate,
                    Notes: line.Notes,
                    CostTypeId: 2,
                    Division: $scope.selectedJob.Division,
                    LineType: 0
                    //Subsistence: $scope.timecardSubsistence
                  },
                  false);
                timecardTemplate.TimecardLines.push(createdLine);

                if ($scope.timecardSubsistence) {
                  var subsistenceCreatedLine = timecardDataContext.createTimecardLine({
                      Employee: crewMember,
                      CostCode: $scope.selectedJob.SubsistenceCostCode,
                      RegularHours: 0.00,
                      OvertimeHours: 0.00,
                      DoubletimeHour: 0.00,
                      TravelHours: 0.00,
                      Job: $scope.selectedJob,
                      Crew: $scope.selectedCrew,
                      Craft: crewMember.Craft,
                      CraftClass: crewMember.CraftClass,
                      WorkDate: $scope.selectedDate,
                      CostTypeId: 2,
                      Division: $scope.selectedJob.Division,
                      LineType: 1,
                      SubsistenceAmount: $scope.selectedJob.SubsistenceAmount
                    },
                    false);

                  timecardTemplate.TimecardLines.push(subsistenceCreatedLine);
                }


              }

            }
          }
          template.TemplateJson = JSON.stringify(templateInformation);
          //templateInformation.Template = template;

          var promise = timecardDataContext.saveTemplateTimecard(template);

          promise.then(function() {
              console.log("save success");
              $scope.templates = timecardDataContext.getTemplates();
            },
            function(reason) {
              console.log("error!");
              console.log(reason);
            });


        }


        //UserProfileService.addTemplateToUser(templateInformation);
        $scope.$close(null);
      };

      $scope.cancelSaveTemplate = function() {
        $scope.$close(null);
      };


};

  //]);

},{}],22:[function(require,module,exports){
'use strict';
//var app = angular.module('frontendApp');

//app.controller('WelcomeCtrl', [
//  '$scope',
//  '$state',
//  '$window',
//  'timecardDataContext',
//  'UserProfileService',
module.exports = function ($scope, $state, $window, timecardDataContext, UserProfileService) {
  var company;

  $scope.companyInfo = {};

  $state.transitionTo('welcome.wizard-company');

  $scope.goState = function(stateName) {
    $state.transitionTo(stateName);
  }

  $scope.saveCompanyInfo = function() {
    if (!company) {
      company = timecardDataContext.createCompany(
        {
          Name: $scope.companyInfo.name,
          RevenueSize: $scope.companyInfo.size,
          Address: $scope.companyInfo.address,
          Address2: $scope.companyInfo.address2,
          City: $scope.companyInfo.city,
          State: $scope.companyInfo.state,
          PostalCode: $scope.companyInfo.postalCode
        }
      );
    }

    //var currentUser = timecardDataContext.getUserById(UserProfileService.getUserProfile().Id);

    timecardDataContext.saveTimecards()
      .then(function(saveResult) {
        UserProfileService.loadUserProfile()
          .then(function() {
            $window.location.href = '/';
            $window.location.reload();
          });

      });
  };
};
//]);

},{}],23:[function(require,module,exports){
'use strict';

angular.module('frontendApp')
  .controller('AboutCtrl', require('./AboutCtrl'))
  .controller('BillingReviewCtrl', require('./BillingReviewCtrl'))
  .controller('CompanyPayCtrl', require('./CompanyPayCtrl'))
  .controller('CompanyPlanCtrl', require('./CompanyPlanCtrl'))
  .controller('CrewTimecardCtrl', require('./CrewTimecardCtrl'))
  .controller('DepartmentSetupCtrl', require('./DepartmentSetupCtrl'))
  .controller('DialogCtrl', require('./DialogCtrl'))
  .controller('EmployeeTimecardCtrl', require('./EmployeeTimecardCtrl'))
  .controller('HelpCtrl', require('./HelpCtrl'))
  .controller('JobSetupCtrl', require('./JobSetupCtrl'))
  .controller('LoginCallbackCtrl', require('./LoginCallbackCtrl'))
  .controller('LoginCtrl', require('./LoginCtrl'))
  .controller('LookupCtrl', require('./LookupCtrl'))
  .controller('MainCtrl', require('./MainCtrl'))
  .controller('NavCtrl', require('./NavCtrl'))
  .controller('NotificationCtrl', require('./NotificationCtrl'))
  .controller('OfficeTimecardCtrl', require('./OfficeTimecardCtrl'))
  .controller('PreferenceCtrl', require('./PreferenceCtrl'))
  .controller('ReviewTimecardCtrl', require('./ReviewTimecardCtrl'))
  .controller('SaveTemplateCtrl', require('./SaveTemplateCtrl'))
  .controller('WelcomeCtrl', require('./WelcomeCtrl'));


},{"./AboutCtrl":2,"./BillingReviewCtrl":3,"./CompanyPayCtrl":4,"./CompanyPlanCtrl":5,"./CrewTimecardCtrl":6,"./DepartmentSetupCtrl":7,"./DialogCtrl":8,"./EmployeeTimecardCtrl":9,"./HelpCtrl":10,"./JobSetupCtrl":11,"./LoginCallbackCtrl":12,"./LoginCtrl":13,"./LookupCtrl":14,"./MainCtrl":15,"./NavCtrl":16,"./NotificationCtrl":17,"./OfficeTimecardCtrl":18,"./PreferenceCtrl":19,"./ReviewTimecardCtrl":20,"./SaveTemplateCtrl":21,"./WelcomeCtrl":22}],24:[function(require,module,exports){
'use strict';
//var app = angular.module('frontendapp');

//app.directive('datepickerPopup',
//[
//  'datepickerPopupConfig', 'dateParser', 'dateFilter',
module.exports = function(datepickerPopupConfig, dateParser, dateFilter) {
  return {
    'restrict': 'A',
    'require': '^ngModel',
    'link': function($scope, element, attrs, ngModel) {
      var dateFormat;

      //*** Temp fix for Angular 1.3 support [#2659](https://github.com/angular-ui/bootstrap/issues/2659)
      attrs.$observe('datepickerPopup',
        function(value) {
          dateFormat = value || datepickerPopupConfig.datepickerPopup;
          ngModel.$render();
        });

      ngModel.$formatters.push(function(value) {
        return ngModel.$isEmpty(value) ? value : dateFilter(value, dateFormat);
      });
    }
  };
};
//]);

},{}],25:[function(require,module,exports){
'use strict';

angular.module('frontendApp')
  .directive('datePickerPopup', require('./datePickerPopup'))
  .directive('punchAreaSelection', require('./punchAreaSelection'))
  .directive('punchBillingDayReview', require('./punchBillingDayReview'))
  .directive('punchCcForm', require('./punchCcForm'))
  .directive('punchCostTypeSelection', require('./punchCostTypeSelection'))
  .directive('punchCraftClassSelection', require('./punchCraftClassSelection'))
  .directive('punchCraftSelection', require('./punchCraftSelection'))
  .directive('punchCustomerSelection', require('./punchCustomerSelection'))
  .directive('punchDateSelection', require('./punchDateSelection'))
  .directive('punchDivisionEditor', require('./punchDivisionEditor'))
  .directive('punchEmployeeFilterSelection', require('./punchEmployeeFilterSelection'))
  .directive('punchEmployeeInformationPanel', require('./punchEmployeeInformationPanel'))
  .directive('punchEmployeeSelection', require('./punchEmployeeSelection'))
  .directive('punchFilterSelection', require('./punchFilterSelection'))
  .directive('punchHoursInput', require('./punchHoursInput'))
  .directive('punchHoursLookup', require('./punchHoursLookup'))
  .directive('punchHoursValidator', require('./punchHoursValidator'))
  .directive('punchJobPanel', require('./punchJobPanel'))
  .directive('punchJobSelection', require('./punchJobSelection'))
  .directive('punchNavbar', require('./punchNavbar'))
  .directive('punchNavbarBrandHeader', require('./punchNavbarBrandHeader'))
  .directive('punchNavItem', require('./punchNavItem'))
  .directive('punchNotification', require('./punchNotification'))
  .directive('punchNotificationList', require('./punchNotificationList'))
  .directive('punchOfficeEntryPanel', require('./punchOfficeEntryPanel'))
  .directive('punchPaymentPlan', require('./punchPaymentPlan'))
  .directive('punchTimeCategorySelection', require('./punchTimeCategorySelection'))
  .directive('smallHeader', require('./smallHeader'));

},{"./datePickerPopup":24,"./punchAreaSelection":26,"./punchBillingDayReview":27,"./punchCcForm":28,"./punchCostTypeSelection":29,"./punchCraftClassSelection":30,"./punchCraftSelection":31,"./punchCustomerSelection":32,"./punchDateSelection":33,"./punchDivisionEditor":34,"./punchEmployeeFilterSelection":35,"./punchEmployeeInformationPanel":36,"./punchEmployeeSelection":37,"./punchFilterSelection":38,"./punchHoursInput":39,"./punchHoursLookup":40,"./punchHoursValidator":41,"./punchJobPanel":42,"./punchJobSelection":43,"./punchNavItem":44,"./punchNavbar":45,"./punchNavbarBrandHeader":46,"./punchNotification":47,"./punchNotificationList":48,"./punchOfficeEntryPanel":49,"./punchPaymentPlan":50,"./punchTimeCategorySelection":51,"./smallHeader":52}],26:[function(require,module,exports){
'use strict';

//var app = angular.module('frontendApp');

//app.directive('punchAreaSelection',
module.exports = function () {
  return {
    scope: {
      selectedJob: '=',
      selectedArea: '=?'
    },
    controller: ['$scope', 'timecardDataContext', function($scope, timecardDataContext) {

    }],
    templateUrl: 'views/directives/punchAreaSelection.directive.html'
  };
};
//);

},{}],27:[function(require,module,exports){
'use strict';

module.exports = function() {
  return {
    restrict: 'E',
    controllerAs: 'bdrVm',
    //scope: {},
    bindToController: {
      reviewDate: '=?'
    },
    controller: function (lodash, $window, Timecard, TimecardService, UserProfileService) {
      var vm = this;
      var rowsEditing = {};

      vm.getTimecards = function () {
        if (!vm.reviewDate) {
          return null;
        }
        //test sdf
        console.log(vm.reviewDate);
        var cards = TimecardService.getTimecardsByDate(vm.reviewDate);
        console.log(cards);
        if (!!cards) {
          var filteredCards = cards.filter(function(elem, index, array) {
            if (vm.unbilledOnly) {
              return elem.ReadyForBilling === false;
            } else {
              return true;
            }
          });
          console.log(filteredCards);
          return filteredCards;
        }


        return cards;
      };
      vm.formatDate = function (date) {
        var dateObj = $window.moment(date);
        return dateObj.format('MM/DD/YYYY');
      };

      vm.editRow = function (row) {
        var id = row.Id;
        rowsEditing[id] = true;
        if (!row.BilledDescription) {
          row.BilledDescription = row.Notes;
          row.BilledHours = row.RegularHours;
        }
      };

      vm.saveRow = function (row) {
        var id = row.Id;
        rowsEditing[id] = false;
        TimecardService.saveTimecardLine(row);
        console.log("Saving row:", row);
      };

      vm.isBilled = function(row) {
        return row.BilledFlag;
      };

      vm.isEditable = function(id) {
        return rowsEditing[id];
      };
    },
    templateUrl: 'views/directives/punchBillingDayReview.directive.html'
  };
};

},{}],28:[function(require,module,exports){
'use strict';

//var app = angular.module('frontendApp');

//app.directive('punchCcForm',
module.exports = function() {
  return {
    scope: {
      amount: '=?'
    },
    controller: [
      '$http', '$scope', 'stripe', function($http, $scope, stripe) {
        $scope.payment = { amount: $scope.amount };
        $scope.charge = function() {
          console.log(stripe);
          return stripe.card.createToken($scope.payment.card)
            .then(function(token) {
              console.log('token created for card ending in ', token.card.last4);
              var payment = angular.copy($scope.payment);
              payment.card = void 0;
              payment.token = token.id;
              return $http.post('https://constructo.int/api/stripe/payment', payment);
            })
            .then(function(payment) {
              console.log('successfully submitted payment for $', payment.amount);
            })
            .catch(function(err) {
              if (err.type && /^Stripe/.test(err.type)) {
                console.log('Stripe error: ', err.message);
              } else {
                console.log('Other error occurred, possibly with your API', err.message);
              }
            });
        };
      }
    ],
    templateUrl: 'views/directives/punchCcForm.directive.html'
  };
};
//);

},{}],29:[function(require,module,exports){

'use strict';
//angular
//  .module('frontendApp')
//  .directive('punchCostTypeSelection', punchCostTypeSelection);

module.exports = function() {
  return {
    scope: {
      selectedCostType: '=?'
    },
    controller: [
      '$scope', 'ConfigService', 'timecardDataContext', function($scope, ConfigService, timecardDataContext) {
        timecardDataContext.getCostTypeById(ConfigService.getDefaultCostTypeId())
          .then(function(data) {
            if (!$scope.selectedCostType) {
              $scope.selectedCostType = data.results[0];
            }

          });

        timecardDataContext.getCostTypes()
          .then(function(data) {
            $scope.costTypes = data.results;
          });
      }
    ],
    templateUrl: 'views/directives/punchCostTypeSelection.directive.html'

  };
};


},{}],30:[function(require,module,exports){
'use strict';

//var app = angular.module('frontendApp');

//app.directive('punchCraftClassSelection',
module.exports = function() {
  return {
    scope: {
      selectedCraft: '=?',
      selectedClass: '=?'
    },
    controller: [
      function() {

      }
    ],
    templateUrl: 'views/directives/punchCraftClassSelection.directive.html'
  };
};
//);

},{}],31:[function(require,module,exports){
'use strict';

//var app = angular.module('frontendApp');

//app.directive('punchCraftSelection',
module.exports = function() {
  return {
    scope: {
      selectedCraft: '=?'
    },
    controller: ['$scope', 'ConfigService', 'timecardDataContext', function ($scope, ConfigService, timecardDataContext) {
      $scope.craftSelectionDisabledFlag = ConfigService.getDisableCraftSelectionFlag();

      if ($scope.craftSelectionDisabledFlag) {
        //$scope.crafts = $scope.selectedCraft;
        $scope.crafts = [$scope.selectedCraft];
      } else {
        $scope.crafts = timecardDataContext.getCraftsLocal(true);
      }

    }],
    templateUrl: 'views/directives/punchCraftSelection.directive.html'
  };
};
//);

},{}],32:[function(require,module,exports){
'use strict';

//var app = angular.module('frontendApp');

//app.directive('punchCustomerSelection',
module.exports = function() {
  return {
    scope: {
      selectedCustomer: '=?'
    },
    controller: [
      '$scope', 'timecardDataContext', function($scope, timecardDataContext) {
        $scope.customers = timecardDataContext.getCustomersLocal();
      }
    ],
    templateUrl: 'views/directives/punchCustomerSelection.directive.html'
  };
};
//);

},{}],33:[function(require,module,exports){
'use strict';
//var app = angular.module('frontendApp');

//app.directive('punchDateSelection',
module.exports = function() {
  return {
    scope: {
      selectedDate: '=?',
    },
    controller: [
      '$scope', function ($scope) {
        if (!$scope.selectedDate) {
          $scope.selectedDate = new Date();
        }


        /* HELPER FUNCTIONS FOR DATE PICKER */

        $scope.clear = function() {
          $scope.selectedDate = null;
        };

        // Disable weekend selection
        $scope.disabled = function(date, mode) {
          //return (mode === 'day' && (date.getDay() === 0 || date.getDay() === 6));
          return false;
        };

        $scope.toggleMin = function() {
          var today = new Date(); //Today's Date
          $scope.minDate = $scope.minDate ? null : new Date(today.getFullYear(), today.getMonth() - 12, today.getDate());
        };
        $scope.toggleMin();

        $scope.open = function($event) {
          $event.preventDefault();
          $event.stopPropagation();

          $scope.opened = true;
        };

        $scope.dateOptions = {
          formatYear: 'yy',
          startingDay: 1
        };

        $scope.formats = ['MM/dd/yy'];
        $scope.format = $scope.formats[0];
      }
    ],
    templateUrl: 'views/directives/punchDateSelection.directive.html'
  };
};
//);

},{}],34:[function(require,module,exports){
'use strict';

//var app = angular.module('frontendApp');

//app.directive('punchDivisionEditor',
module.exports = function() {
  return {
    scope: {
      division: '=?',
      saveAction: '='
    },
    controller: [
      '$scope', '$state', 'timecardDataContext', function($scope, $state, timecardDataContext) {
        $scope.saveDivision = function() {
          if ($scope.saveAction && angular.isFunction($scope.saveAction)) {
            $scope.saveAction();
            return;
          }
          timecardDataContext.saveTimecards()
            .then(function() {
              $state.go('company.department');
            });
        };

        $scope.parentState = function() {
          $state.go('^');
        };
      }
    ],
    templateUrl: 'views/directives/punchDivisionEditor.directive.html'
  };
};
//);

},{}],35:[function(require,module,exports){
'use strict';

//var app = angular.module('frontendApp');

//app.directive('punchEmployeeFilterSelection',
module.exports = function() {
  return {
    scope: {
      selectedEmployeeFilter: '=?'
    },
    controller: ['$scope', 'lodash', 'UserProfileService', function ($scope, lodash, UserProfileService) {
      $scope.employeeFilters = [
        {
          name: 'All Employees',
          filterFunction: function (item) {
            return true;
          }
        },
        {
          name: 'Bakersfield',
          filterFunction: function (item) {
            if (!item)
              return false;

            if (!item.Division)
              return false;

            return item.Division.Code === 'B';
          }
        },
        {
          name: 'Fresno',
          filterFunction: function (item) {
            if (!item)
              return false;

            if (!item.Division)
              return false;

            return item.Division.Code === 'F';
          }
        },
        {
          name: 'Ontario',
          filterFunction: function (item) {
            if (!item)
              return false;

            if (!item.Division)
              return false;

            return item.Division.Code === 'O';
          }
        },
        {
          name: 'Visalia',
          filterFunction: function (item) {
            if (!item)
              return false;

            if (!item.Division)
              return false;

            return item.Division.Code === 'V';
          }
        }
      ];
    }],
    templateUrl: 'views/directives/punchEmployeeFilterSelection.directive.html'
  };
};
//);

},{}],36:[function(require,module,exports){
'use strict';
//var app = angular.module('frontendApp');

//app.directive('punchEmployeeInformationPanel',
module.exports = function() {
  return {
    scope: {
      selectedEmployee: '=?'
    },
    controller: [
      '$scope', function($scope) {
        $scope.smallDisplay = true;

        $scope.toggleDisplayMode = function() {
          $scope.smallDisplay = !$scope.smallDisplay;
        };

      }
    ],
    templateUrl: 'views/directives/punchEmployeeInformationPanel.directive.html'
  };
};
//);

},{}],37:[function(require,module,exports){
'use strict';
//var app = angular.module('frontendApp');

//app.directive('punchEmployeeSelection',
module.exports = function() {
  return {
    scope: {
      selectedEmployee: '=',
      employeeList: '=?',
      employeeFilter: '=?'
    },
    controller: ['$log', '$scope', 'ConfigService', 'timecardDataContext', function ($log, $scope, ConfigService, timecardDataContext) {
      var logger = $log.getInstance('punchEmployeeSelect');

      var employeeTypeFilter = ConfigService.getEmployeeTypeFilter();

      $scope.employees = !employeeTypeFilter ? timecardDataContext.getEmployeesLocal(true) : timecardDataContext.getEmployeesByTypeLocal(employeeTypeFilter, true);

        $scope.addEmployee = function(emp) {
          try {
            if (!emp) {
              return;
            }
            if (!$scope.employeeList) {
              $scope.employeeList = [];
            }
            var employeeIndex = $scope.employeeList.indexOf(emp);
            if (employeeIndex === -1) {
              $scope.employeeList.push(emp);
            }
          } catch (err) {
            logger.warn('Error adding employee to list');
          }
        };

        $scope.filterEmployees = function(filterSelectOptions) {
          return function(item) {
            if (!filterSelectOptions) {
              return true;
            }
            return filterSelectOptions.filterFunction(item);
          };
        };

        $scope.removeEmployee = function(emp) {
          try {
            if (!emp) {
              return;
            }
            if (!$scope.employeeList) {
              $scope.employeeList = [];
            }
            var employeeIndex = $scope.employeeList.indexOf(emp);
            if (employeeIndex !== -1) {
              $scope.employeeList.splice(employeeIndex, 1);
            }
          } catch (err) {
            $log.warn('Error removing employee from list');
          }
        };

      }
    ],
    templateUrl: 'views/directives/punchEmployeeSelection.directive.html'
  };
};
//);


},{}],38:[function(require,module,exports){
'use strict';

//var app = angular.module('frontendApp');

//app.directive('punchFilterSelection',
module.exports = function() {
  return {
    scope: {
      selectedFilter: '=?'
    },
    controller: [
      '$scope', 'lodash', 'UserProfileService', function($scope, lodash, UserProfileService) {
        $scope.jobFilters = [
          {
            name: 'All Jobs',
            filterFunction: function(item) {
              return true;
            }
          },
          {
            name: 'My Active Jobs (Last 30 Days)',
            filterFunction: function(item) {
              var recentJobs = UserProfileService.getRecentJobIds();
              if (!recentJobs) {
                return false;
              } else {
                return lodash.includes(recentJobs, item.Id);
              }

            }
          },
          {
            name: 'Bakersfield',
            filterFunction: function(item) {
              return item.Division.Code === 'B';
            }
          },
          {
            name: 'Fresno',
            filterFunction: function(item) {
              return item.Division.Code === 'F';
            }
          },
          {
            name: 'Ontario',
            filterFunction: function (item) {
              return item.Division.Code === 'O';
            }
          },
          {
            name: 'Visalia',
            filterFunction: function(item) {
              return item.Division.Code === 'V';
            }
          }
        ];
      }
    ],
    templateUrl: 'views/directives/punchFilterSelection.directive.html'
  };
};
//);

},{}],39:[function(require,module,exports){
'use strict';

//var app = angular.module('frontendApp');

//app.directive('punchHoursInput',
module.exports = function() {
  return {
    scope: {
      hoursValue: '='
    },
    controller: [
      function() {
        var vm = this;


        vm.getSeverity = function(error) {
          return "tooltip-warning";
        };

        vm.getErrorText = function(error) {
          /*
           *   <!--<span ng-show="hoursInputForm.hoursValue.$error.invalidNumber">You have to enter a correct format</span>
              <span ng-show="hoursInputForm.hoursValue.$error.maxHours">You cannot enter more than 10 hours.</span>
              <span ng-show="hoursInputForm.hoursValue.$error.minHours">You cannot enter less than 0 hours.</span>-->
           *
           */
          if (!error) {
            return;
          }

          var errorText = "The entry is invalid";

          if (error.invalidNumber) {
            errorText = "You have to enter a correctly formatted number.";
          } else if (error.maxHours) {
            errorText = "You cannot enter more than 20 hours.";
          } else if (error.minHours) {
            errorText = "You cannot enter less than 0 hours.";
          }
          return errorText;
        };
      }
    ],
    controllerAs: 'ctrl',
    bindToController: true,
    templateUrl: 'views/directives/punchHoursInput.directive.html'
  };
};
//);

},{}],40:[function(require,module,exports){
module.exports = function () {
  return {
    restrict: 'E',
    controllerAs: 'hlVm',
    //scope: {},
    bindToController: {
      startDate: '=?',
      endDate: '=?',
      lookupType: '=?'
    },
    controller: function (lodash, $window, TimecardService, UserProfileService) {
      var vm = this;


      vm.formatDate = function (date) {
        var dateObj = $window.moment(date);
        return dateObj.format('MM/DD/YYYY');
      };

      vm.getUserTimecardsByDay = function() {
        var user = UserProfileService.getUserProfile();
        var timecards = TimecardService.getUserTimecards(user);
        //var timecards = vm.getUserTimecards();

        var returnOutput = [];
        var values = lodash.memoize(
          function (tcs, startParam, endParam) {
            console.log('tcs were passed', tcs);
            var output = lodash.chain(tcs)
              .filter(function (t) {
                var start = $window.moment(startParam);
                var end = $window.moment(endParam);
                var workDate = $window.moment(t.WorkDate);
                var filterPassed = workDate.isSameOrAfter(start) && workDate.isSameOrBefore(end);

                console.log('did pass filter?', t, filterPassed);
                return filterPassed;
              })
              .map(function (tc) {
                console.log('passed tc', tc);
                var ary = {};
                var dt = $window.moment(tc.WorkDate);
                ary['date'] = dt.format('MM-DD-YYYY');
                ary['regHours'] = tc.RegularHours;
                ary['otHours'] = tc.OvertimeHours;
                ary['dtHours'] = tc.DoubletimeHours;
                ary['raw'] = tc;

                return ary;
              })
             //.reduce(function (result, value, key) {
             //  if (!result[value.date]) {
             //    result[value.date] = {
             //      'regHours': value.regHours,
             //      'otHours': value.otHours,
             //      'dtHours': value.dtHours,
             //      'lines': [
             //           value.raw
             //      ]
             //    };
             //  } else {
             //    result[value.date].regHours += value.regHours;
             //    result[value.date].otHours += value.otHours;
             //    result[value.date].dtHours += value.dtHours;
             //    result[value.date].lines.push(value.raw);
             //  }

             //  return result;
             //}, {})
             .value();

          return output;

          }
        );

        returnOutput = values(timecards, vm.startDate, vm.endDate);

        return returnOutput;
      };

      vm.getAllTimecards = function () {
        console.log('startDate', vm.startDate);
        console.log('endDate', vm.endDate);
        console.log('viewmodel', vm);
        var start = $window.moment(vm.startDate);
        var end = $window.moment(vm.endDate);

        if (!vm.loadedStart || !vm.loadedEnd) {
          vm.loadedStart = start.format('YYYY-MM-DD');
          vm.loadedEnd = end.format('YYYY-MM-DD');
        }

        if (vm.loadedStart !== start.format('YYYY-MM-DD') || vm.loadedEnd !== end.format('YYYY-MM-DD')) {

          console.log('time to reload data');
          TimecardService.clearTimecardCache();
          vm.loadedStart = start.format('YYYY-MM-DD');
          vm.loadedEnd = end.format('YYYY-MM-DD');
        }


        var timecards = TimecardService.getAllTimecards(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));


        var filteredTimecards = null;

        // If there's no filters set - fall through
        if (!!vm.startDate && !!vm.endDate) {
          console.log('filtering timecards');

          // build memoize function - will remember inputs and cache outputs
          var values = lodash.memoize(function (startParam, endParam) {
            var returnAry = lodash.filter(timecards,
              function (t) {
                var start = $window.moment(startParam);
                var end = $window.moment(endParam);
                var workDate = $window.moment(t.WorkDate);

                return workDate.isSameOrAfter(start) && workDate.isSameOrBefore(end);
              });

            return returnAry;
          });

          // run filter memoize funciton
          filteredTimecards = values(vm.startDate, vm.endDate);

          console.log('filtered timecards', filteredTimecards);
        }

        if (!filteredTimecards) {
          return timecards;
        }
        return filteredTimecards;
      };

      vm.getUserTimecards = function () {
        console.log('startDate', vm.startDate);
        console.log('endDate', vm.endDate);
        var user = UserProfileService.getUserProfile();
        var timecards = TimecardService.getUserTimecards(user);
        var filteredTimecards = null;

        // If there's no filters set - fall through
        if (!!vm.startDate && !!vm.endDate) {
          console.log('filtering timecards');

          // build memoize function - will remember inputs and cache outputs
          var values = lodash.memoize(function (startParam, endParam) {
            var returnAry = lodash.filter(timecards,
              function(t) {
                var start = $window.moment(startParam);
                var end = $window.moment(endParam);
                var workDate = $window.moment(t.WorkDate);

                return workDate.isSameOrAfter(start) && workDate.isSameOrBefore(end);
              });

            return returnAry;
          });

          // run filter memoize funciton
          filteredTimecards = values(vm.startDate, vm.endDate);

          console.log('filtered timecards', filteredTimecards);
        }

        if (!filteredTimecards) {
          return timecards;
        }
        return filteredTimecards;
      };

      vm.getTimecards = function() {
        if (vm.lookupType === 'all') {
          return vm.getAllTimecards();
        } else {
          return vm.getUserTimecards();
        }
      };

    },
    templateUrl: 'views/directives/punchHoursLookup.directive.html'
  };
};

},{}],41:[function(require,module,exports){
'use strict';

//var app = angular.module('frontendApp');

//app.directive('punchHoursValidator', [
module.exports = function() {
  return {
    require: 'ngModel',
    link: function(scope, elm, attrs, ctrl) {
      ctrl.$validators.invalidNumber = function(modelValue, viewValue) {
        if (ctrl.$isEmpty(modelValue)) {
          // consider empty models to be valid
          return true;
        }
        //console.log(math);
        //// removed !isNaN(viewValue) in favor of mathjs isNumeric()
        if (viewValue.substr(0, 1) !== ' ' && !isNaN(parseFloat(viewValue)) && isFinite(viewValue)) {
          return true;
        }

        return false;
        // return !isNaN(parseFloat(viewValue)) && isFinite(viewValue);
      }
      ctrl.$validators.maxHours = function(modelValue, viewValue) {
        if (ctrl.$isEmpty(modelValue)) {
          // consider empty models to be valid
          return true;
        }

        if (viewValue <= 20) {

          // it is valid
          return true;
        }

        // it is invalid
        return false;
      };

      ctrl.$validators.minHours = function(modelValue, viewValue) {
        if (ctrl.$isEmpty(modelValue)) {
          // consider empty models to be valid
          return true;
        }
        // possibly add negative hours check here
        if (viewValue >= 0) {
          return true;
        }

        return false;
      }
    }
  };
};
//]);

},{}],42:[function(require,module,exports){
'use strict';

//var app = angular.module('frontendApp');

//app.directive('punchJobPanel',
module.exports = function() {
  return {
    scope: {
      actionButtons: '=?',
      selectedArea: '=?',
      selectedDate: '=?',
      selectedFilter: '=?',
      selectedJob: '=?'
    },
    controller: [
      '$scope', function($scope) {
        $scope.filterActiveAreas = true;
        $scope.filterActiveJobs = true;


        $scope.today = function() {
          $scope.selectedDate = new Date();
        };
        $scope.today();

        $scope.clear = function() {
          $scope.selectedDate = null;
        };

        // Disable weekend selection
        $scope.disabled = function(date, mode) {
          //return (mode === 'day' && (date.getDay() === 0 || date.getDay() === 6));
          return false;
        };

        $scope.toggleMin = function() {
          var today = new Date(); //Today's Date
          $scope.minDate = $scope.minDate ? null : new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
        };
        $scope.toggleMin();

        $scope.open = function($event) {
          $event.preventDefault();
          $event.stopPropagation();

          $scope.opened = true;
        };

        $scope.dateOptions = {
          formatYear: 'yy',
          startingDay: 1
        };

        $scope.formats = ['MM/dd/yy'];
        $scope.format = $scope.formats[0];


      }
    ],
    templateUrl: 'views/directives/punchJobPanel.directive.html'
  };
};
//);

},{}],43:[function(require,module,exports){
'use strict';

//var app = angular.module('frontendApp');

//app.directive('punchJobSelection',
module.exports = function() {
  return {
    scope: {
      selectedJob: '=?',
      customerFilter: '=?',
      jobFilter: '=?',
      jobActiveFilter: '=?'
    },
    controller: [
      '$scope', 'timecardDataContext', function($scope, timecardDataContext) {
        $scope.filterActiveJobs = function(filterEnable) {
          return function(item) {
            if (!filterEnable) {
              return true;
            } else {
              return item.ActiveFlag;
            }


          };
        };

        $scope.filterCustomers = function(customer) {
          return function(item) {
            if (!customer) {
              return true;
            }
            return customer.Id === item.CustomerId;
          };
        };

        $scope.filterJobs = function(filterSelectOptions) {
          return function(item) {
            if (!filterSelectOptions) {
              return true;
            }
            return filterSelectOptions.filterFunction(item);
          };
        };

        $scope.jobs = timecardDataContext.getJobsLocal();


      }
    ],
    templateUrl: 'views/directives/punchJobSelection.directive.html'
  };
};
//);

},{}],44:[function(require,module,exports){
'use strict';

//var app = angular.module('frontendApp');

//app.directive('punchNavItem',
module.exports = function() {
  return {
    scope: {
      navItem: '=?'
    },
    controller: [
      function() {

      }
    ],
    templateUrl: 'views/directives/punchNavItem.directive.html'
  };
};
//);

},{}],45:[function(require,module,exports){
'use strict';

//var app = angular.module('frontendApp');

//app.directive('punchNavbar',
module.exports = function() {
    return {
      scope: {
        isCollapsed: '=?'
      },
      controller: [
        'lodash', '$log', '$scope', 'AppStateService', 'Navbar', function(lodash, $log, $scope, AppStateService, Navbar) {
          $scope.navbar = Navbar.getNavbarItems();
          Navbar.getCustomItems().then(function (result) {
            console.log('got stuff', result.data);


            $scope.customNavbar = lodash.orderBy(result.data, ['order'], ['asc']);;
          });
          $scope.toggleMenu = function(item) {
            $log.debug('expanding menu', item);
            Navbar.toggleMenu(item);
          };

          $scope.isItemVisible = function (item) {
            return Navbar.isItemVisible(item);
          };
          //$scope.$watch(function () { return AppStateService.getTimecardsToReview(); }, function(newValue, oldValue) {
          //  if (newValue) {
          //    $scope.navbar = Navbar.getNavbarItems();
          //    console.log(Navbar.getNavbarItems());
          //  }
          //}, false);
        }
      ],

      templateUrl: 'views/directives/punchNavbar.directive.html'
    };
  };
//);

},{}],46:[function(require,module,exports){
'use strict';


//app.directive('punchNavbarBrandHeader', ['AppStateService',
module.exports = function (AppStateService) {
  return {

    template: '<a class="navbar-brand" href="#/home"><img ng-src="{{AppStateService.getCustomerSmallLogo()}}" height="30" width="30" />' + AppStateService.getProductCustomer() + ' | ' + AppStateService.getProductName() + '</a>'
  };
};
//]);

},{}],47:[function(require,module,exports){
'use strict';


module.exports = function() {
  return {
    restrict: 'E',
    scope: {
      notification: '='
    },
    controller: [
      '$scope', function($scope) {
        $scope.executeAction = function(action) {
          action();
        };
      }
    ],
    templateUrl: 'views/directives/punchNotification.html'
  };
};

},{}],48:[function(require,module,exports){
'use strict';

module.exports = function() {
  return {
    restrict: 'E',
    controller: [
      '$scope', 'NotificationService', function($scope, NotificationService) {
        $scope.notifications = NotificationService.getNotifications();
      }
    ],
    templateUrl: 'views/directives/punchNotificationList.html'
  };
};

},{}],49:[function(require,module,exports){
'use strict';
//var app = angular.module('frontendApp');

//app.directive('punchOfficeEntryPanel',
module.exports = function() {
  return {
    scope: {
      entryArray: '=?'
    },
    controller: [
      '$scope',
      'ConfigService',
      'timecardDataContext',
      function ($scope, ConfigService, timecardDataContext) {

        var getRowInformation = function(line) {

          var returnValue = {
            'OriginalValues': [
              { 'RegularHours': line.RegularHours },
              { 'OvertimeHours': line.OvertimeHours },
              { 'TravelHours': line.TravelHours },
              { 'SubsistenceAmount': line.SubsistenceAmount },
              { 'Notes': line.Notes }
            ],
            'ChangedFields': []
          };

          return JSON.stringify(returnValue);

        };

        var clearData = function() {
          //$scope.selectedDate = new Date();
          $scope.selectedCustomer = '';
          $scope.selectedJob = '';
          $scope.numHours = 0;
          $scope.notes = null;
        };

        var clearHoursNotes = function() {
          $scope.numHours = 0;
          $scope.notes = null;
        };

        $scope.clearButton = function() {
          clearData();
        };

        $scope.addToEntryArray = function (date, customer, job, hours, notes, clear, category) {
          console.log("Cat",category);
          var catId = null;
          if (!!category) {
            catId = category.id;
          }
          var createdLine = timecardDataContext.createTimecardLine({
              RegularHours: hours,
              OvertimeHours: 0.0,
              TravelHours: 0.0,
              SubsistenceAmount: 0.0,
              Job: job,
              WorkDate: date,
              Notes: notes,
              CostTypeId: ConfigService.getDefaultCostTypeId(),
              LineType: 0,
              TimecardLineCategoryId: catId,
              RowInformation: getRowInformation({
                RegularHours: hours,
                OvertimeHours: 0.0,
                TravelHours: 0.0,
                SubsistenceAmount: 0.0,
                Notes: notes
              })
            },
            true);

          console.log(createdLine);

          $scope.entryArray.push(createdLine);
          if (clear) {
            clearData();
          } else {
            clearHoursNotes();
          }
        };

        $scope.test = function(form) {
          console.log(form);
        };

      }
    ],
    templateUrl: 'views/directives/punchOfficeEntryPanel.directive.html'
  };

};
//);

},{}],50:[function(require,module,exports){
'use strict';

//var app = angular.module('frontendApp');

//app.directive('punchPaymentPlan',
module.exports = function() {
  return {
    scope: {
      plan: '=?'
    },
    controller: [
      '$scope', function($scope) {


      }
    ],
    templateUrl: 'views/directives/punchPaymentPlan.directive.html'
  };
};
//);

},{}],51:[function(require,module,exports){
'use strict';

module.exports = function() {
  return {
    restrict: 'E',
    controllerAs: 'tcVm',
    scope: null,
    bindToController: {
      selectedCategory: '=?'
    },
    controller: function(TimecardService) {
      var vm = this;

      vm.getCategories = function () {
        var cats = TimecardService.getTimecardCategories();
        return cats;
      }
    },
    templateUrl: 'views/directives/punchTimeCategorySelection.directive.html'
  };
};

},{}],52:[function(require,module,exports){
//var app = angular.module('frontendApp');
//app.directive('smallHeader', ['$window',
'use strict';
module.exports = function($window) {
  return function(scope, elm, attr) {
    angular.element(window)
      .bind('scroll',
        function() {
          if (angular.element('.navbar').offset().top > 150) {
            angular.element('nav.navbar-punch').removeClass('hide');
          } else {
            angular.element('nav.navbar-punch').addClass('hide');
          }
        });
  };
};
//]);

},{}],53:[function(require,module,exports){
'use strict';

angular.module('frontendApp')
  .filter('sumChildren', require('./sumchildren'));

},{"./sumchildren":54}],54:[function(require,module,exports){
'use strict';

/**
 * @ngdoc filter
 * @name frontendApp.filter:sumChildren
 * @function
 * @description
 * # sumChildren
 * Filter in the frontendApp.
// */
//angular.module('frontendApp')
//  .filter('sumChildren', ['$log', '$window',
module.exports = function($log, $window) {

  return function(data, key) {
    var sum = 0.0;
    try {
      for (var i = 0; i < data.length; i++) {

        if (isNaN(data[i][key]) || data[i][key] === null) {
          //$log.debug("should continue!");
          continue;
        }
        sum += $window.math.round(parseFloat(data[i][key]), 2);
      }
      return sum;
    } catch (ex) {
      //$log.debug(ex, data, key, data[i][key]);
      return 0.0;
    }
  };
};
//]);

},{}],55:[function(require,module,exports){
'use strict';

/**
 * @ngdoc service
 * @name frontendApp.AppStateService
 * @description
 * # AppStateService
 * Factory in the frontendApp.
 */
//angular.module('frontendApp')
//  .factory('AppStateService', ['ConfigService',
module.exports = function(ConfigService) {
  var productName = ConfigService.getProductName();
  var productCustomer = ConfigService.getCustomerName();
  var productBuild = ConfigService.getProductBuild();
  var productDeployDate = ConfigService.getProductDeployDate();
  var title = productCustomer + ' | ' + productName;

  var unpostedTimecards = [];
  var applicationAlerts = [];
  var globalTimecardsToReview = 0;

    var getLogTags = function() {
      return {
        'customer': productCustomer,
        'deploy-date': productDeployDate
      };
    };

  var getVersionString = function() {
    var versionString = productName + ' ' + productBuild + ' | Customer: ' + productCustomer;

    return versionString;
  };

  var getVersion = function() {
    return productBuild;
  }

  var customerSmallLogo = ConfigService.getSmallLogo();
  var dbmLogo = 'https://www.dbrownmanagement.com/media/1314/apple-touch-icon-76x76-precomposed.png';

  var applicationStateData = {
    applicationAlerts: applicationAlerts,
    getVersion: getVersion,
    timecardsToReview: globalTimecardsToReview,
    unpostedTimecards: unpostedTimecards,
    pageTitle: function() {
      return title;
    },
    setTitle: function(newTitle) { title = newTitle; },
    getCustomerSmallLogo: function() {
      return customerSmallLogo;
    },
    getDBMLogo: function() {
      return dbmLogo;
    },
    getLogTags: getLogTags,
    getProductName: function() {
      return productName;
    },
    getProductCustomer: function() {
      return productCustomer;
    },
    getTimecardsToReview: function() {
      return globalTimecardsToReview;
    },
    setTimecardsToReview: function(amount) {
      globalTimecardsToReview = amount;
    },
    getTitle: function() {
      return title;
    },
    getVersionString: function() {
      return getVersionString();
    }

  };

  return applicationStateData;
};
//]);

},{}],56:[function(require,module,exports){
'use strict';

/**
 * @ngdoc service
 * @name frontendApp.ConfigService
 * @description
 * # ConfigService
 * Factory in the frontendApp.
 */
module.exports = function (appConfig, appInfo, appUrls) {
  // APP STATE INITIAL VARIABLES //
  var productBuild = appInfo.productBuild;
  var productName = appInfo.productName;
  var productDeployDate = appInfo.productDeployDate;
  // API KEYS //
  var auth0ClientId = appConfig.auth0ClientId;
  var auth0ApiClientId = appConfig.auth0ApiClientId;
  var pusherToken = appConfig.pusherToken;
  var raygunApiKey = appConfig.raygunApiKey;
  var stripePublishableKey = appConfig.stripePublishableKey;

  // SERVICE ENDPOINTS //
  var apiRoot = appUrls.apiEndpoint;
  var apiEndpoint = apiRoot + '/breeze/data';
  var miscApiEndpoint = apiRoot + '/api';
  var pusherAuthEndpoint = apiRoot + '/api/PusherApi/DoAuthorization';

  // CUSTOMER BRANDING //
  var customerCode = appConfig.customerCode;
  var customerName = appConfig.customerName;
  var preferredLanguage = appConfig.preferredLanguage;
  var smallLogo = appConfig.smallLogo;
  var bigLogo = appConfig.bigLogo;

  // APPLICATION OPTIONS //
  var defaultCostTypeId = appConfig.defaultCostTypeId;
  var useRaygun = appConfig.useRaygun;
  var debugFlag = appConfig.debugFlag;
  var disableCraftSelection = appConfig.disableCraftSelection;
  var employeeTypeFilter = appConfig.employeeTypeFilter;

  // VENDOR MISC //
  var auth0EndpointUrl = appConfig.auth0EndpointUrl;
  var auth0Scopes = appConfig.auth0Scopes;
  var pusherPresenceChannel = appConfig.pusherPresenceChannel;
  var pusherPrivateChannel = appConfig.pusherPrivateChannel;
  var pusherPublicChannel = appConfig.pusherPublicChannel;
  var pusherUserChannelPrefix = appConfig.pusherUserChannelPrefix;

  var configServiceData = {
    useRaygun: function () { return useRaygun; },
    getApiRoot: function () { return apiRoot; },
    getCustomerCode: function () { return customerCode; },
    getCustomerName: function() { return customerName;  },
    getApiEndpoint: function () { return apiEndpoint; },
    getMiscApiEndpoint: function () { return miscApiEndpoint; },
    getDefaultCostTypeId: function () { return defaultCostTypeId; },
    getDebugFlag: function () { return debugFlag; },
    getDisableCraftSelectionFlag: function () { return disableCraftSelection; },
    getEmployeeTypeFilter: function() { return employeeTypeFilter },
    getAuth0ClientId: function () { return auth0ClientId; },
    getAuth0ApiClientId: function () { return auth0ApiClientId; },
    getAuth0EndpointUrl: function () { return auth0EndpointUrl; },
    getAuth0Scopes: function() { return auth0Scopes; },
    getRaygunApiKey: function () { return raygunApiKey; },
    getProductBuild: function () { return productBuild; },
    getProductName: function () { return productName; },
    getProductDeployDate: function () { return productDeployDate; },
    getStripePublishableKey: function() { return stripePublishableKey; },
    getSmallLogo: function () { return smallLogo; },
    getBigLogo: function() { return bigLogo; },
    getPreferredLanguage: function() { return preferredLanguage; },
    getPusherAuthEndpoint: function () { return pusherAuthEndpoint; },
    getPusherPresenceChannel: function () { return pusherPresenceChannel; },
    getPusherPrivateChannel: function () { return pusherPrivateChannel; },
    getPusherPublicChannel: function() { return pusherPublicChannel; },
    getPusherUserChannelPrefix: function () { return pusherUserChannelPrefix; },
    getPusherToken: function () { return pusherToken; }
  };

  return configServiceData;
};
//);

},{}],57:[function(require,module,exports){
'use strict';

module.exports = function (Restangular) {

  var getAllFeatures = function() {
    return Restangular.all('features').getList();
  };

  return {
    getAllFeatures: getAllFeatures
  };
};

},{}],58:[function(require,module,exports){
'use strict';

module.exports = function (Feature, lodash) {
  var features = [];
  var featuresLoaded = false;
  var featuresLoading = false;

  var getAllFeatures = function () {

    if (!featuresLoaded && !featuresLoading) {
      loadFeatures();
    }
    return features;
  };

  var isFeatureEnabled = function (feature) {
    if (!featuresLoaded && !featuresLoading) {
      loadFeatures();
    }
    var featureObj = lodash.find(features, ['featureCode', feature]);

    if (!featureObj) {
      return false;
    }

    return featureObj.enabled;
  };

  var loadFeatures = function() {
    featuresLoading = true;

    Feature.getAllFeatures().then(function(data) {
      features = data;
      featuresLoaded = true;
      featuresLoading = false;
    });

  };

  return {
    getAllFeatures: getAllFeatures,
    isFeatureEnabled: isFeatureEnabled
  };
};

},{}],59:[function(require,module,exports){
'use strict';

/**
 * @ngdoc service
 * @name frontendApp.LoginInterceptor
 * @description
 * # LoginInterceptor
 * Factory in the frontendApp.
 */
module.exports =
    function($injector, $q, $rootScope, $log) {
      return {
        request: function(config) {
          var lock = $injector.get('lock');
          var ConfigService = $injector.get('ConfigService');
          var LoginStateFactory = $injector.get('LoginStateFactory');
         var tok = LoginStateFactory.getLockToken();

          //console.log('tok ', tok);
          
          var targetClientId = ConfigService.getAuth0ApiClientId(); // Another App
          var options = { scope: 'openid name email role user_id accountingemployeecode' };
          config.headers = config.headers || {};
          //$log.info("[INTER] In request interceptor");
          //var apiKey = LoginStateFactory.getApiKey();
          var apiKey = tok;
          //$log.info("[INTER] request api key ", apiKey);
          // Is this request for the secondary app?
          if (config.url.indexOf(ConfigService.getApiRoot()) === 0) {

            if (!apiKey) { return config; }
            config.headers.Authorization = 'Bearer ' + apiKey;
            return config;
          } else if (config.url.indexOf(ConfigService.getMiscApiEndpoint() === 0)) {
            if (!apiKey) {
              return config;
            }
            config.headers.Authorization = 'Bearer ' + apiKey;
            return config;
          } else {
            if (lock.id_token) {
              config.headers.Authorization = 'Bearer ' + lock.id_token;
            }
            return config;
          }
        },
        responseError: function(response) {
          // handle the case where the user is not authenticated
          if (response.status === 401) {
            $rootScope.$broadcast('auth:forbidden', response);
          }
          return $q.reject(response);
        }
      };
    };
  //]);

},{}],60:[function(require,module,exports){
'use strict';

//var app = angular.module('frontendApp');

//app.factory('Navbar', ['$rootScope', 'AppStateService', 'UserProfileService',
module.exports = function ($http, lodash, $log, $rootScope, AppStateService, ConfigService, FeatureService, UserProfileService, LoginStateFactory) {
  var navbarItems;
  var userProfile = LoginStateFactory.getUserProfile();

  var rebuildNavbar = function() {
    navbarItems = [
      {
        id: 1,
        title: 'Home',
        state: 'home',
        icon: 'fa-home',
        translation: 'TITLE_HOME',
        featureName: 'FEATURE_SHELL',
        showOnSidebar: true,
        showOnTopBar: false,
        visibilityRules: {
          showLoggedOut: true,
          showFirstUse: true
        }
      },
      {
        id: 2,
        title: 'Field Timecard',
        state: 'crew-timecard',
        featureName: 'FEATURE_CREW_TIMECARD',
        icon: 'fa-road',
        translation: 'TITLE_CREW_TIMECARD',
        showOnSidebar: true,
        showOnTopBar: false,
        visibilityRules: {
          showLoggedOut: false,
          showFirstUse: true
        }
      },
      {
        id: 3,
        title: 'Employee Timecard',
        state: 'employee-timecard',
        featureName:'FEATURE_OFFICE_TIMECARD',
        icon: 'fa-clock-o',
        translation: 'TITLE_OFFICE_TIMECARD',
        showOnSidebar: true,
        showOnTopBar: false,
        visibilityRules: {
          showLoggedOut: false,
          showFirstUse: true
        }
      },
      {
        id: 4,
        title: 'Review',
        state: 'review-timecard',
        featureName: 'FEATURE_REVIEW_TIMECARD',
        icon: 'fa-edit',
        translation: 'TITLE_REVIEW_TIMECARD',
        indicator: {
          value: function() { return AppStateService.getTimecardsToReview(); },
          // value: AppStateService.getTimecardsToReview(),
          indicatorClass: 'label-primary'
        },
        showOnSidebar: true,
        showOnTopBar: false,
        visibilityRules: {
          showLoggedOut: false,
          showFirstUse: true
        }
      },
      {
        id: 9,
        title: 'Lookup',
        state: 'lookup',
        featureName: 'FEATURE_LOOKUP_TIMECARD',
        icon: 'fa-search',
        translation: 'TITLE_LOOKUP_TIMECARD',
        showOnSidebar: true,
        showOnTopBar: false,
        visibilityRules: {
          showLoggedOut: false,
          showFirstUse: true
        }
      },
      {
        id: 10,
        title: 'Billing Review',
        state: 'billing-review',
        featureName: 'FEATURE_BILLING_REVIEW',
        icon: 'fa-usd',
        translation: 'TITLE_BILLING_REVIEW',
        showOnSidebar: true,
        showOnTopBar: false,
        visibilityRules: {
          showLoggedOut: false,
          showFirstUse: true
        }
      },
      //{ title: 'Reports', state: 'reports', icon: 'fa-area-chart' },
      {
        id: 5,
        title: 'Company Setup',
        icon: 'fa-building',
        translation: 'TITLE_COMPANY_SETUP',
        featureName: 'FEATURE_COMPANY_SETUP',
        menuCollapsed: true,
        children: [
          { title: 'Account Plan', translation: 'TITLE_ACCOUNT_PLAN', state: 'company.plan', icon: 'fa-credit-card' },
          //  { title: 'Defaults', state: 'company.defaults', icon: 'fa-wrench' },
          {
            title: 'Departments',
            translation: 'TITLE_DEPARTMENT_SETUP',
            state: 'company.department',
            icon: 'fa-university'
          },
          { title: 'Jobs / Cost Codes', translation: 'TITLE_JOB_SETUP', state: 'company.job', icon: 'fa-road' },
          //  { title: 'Users', state: 'company.user', icon: 'fa-user' },
          // { title: 'Employees', state: 'company.employee', icon: 'fa-group' }
        ],
        showOnSidebar: true,
        showOnTopBar: false,
        visibilityRules: {
          showLoggedOut: false,
          showFirstUse: false
        }
      },
      {
        id: 6,
        title: 'Preferences',
        icon: 'fa-gear',
        state: 'preferences',
        featureName: 'FEATURE_PREFERENCES',
        translation: 'TITLE_PREFERENCES',
        showOnSidebar: true,
        showOnTopBar: false,
        visibilityRules: {
          showLoggedOut: false,
          showFirstUse: true
        }
        //children: [
        //  { title: 'My Account', state: 'myaccount', icon: 'fa-user', translation: 'ACCOUNT_NAV', showOnSidebar: true, showOnTopBar: false }
        //],

      },
      {
        id: 7,
        featureName: 'FEATURE_SHELL',
        title: 'Help',
        state: 'help',
        icon: 'fa-life-ring',
        translation: 'TITLE_HELP',
        showOnSidebar: true,
        showOnTopBar: false,
        visibilityRules: {
          showLoggedOut: true,
          showFirstUse: true
        }
      }
    ];
  };



  var getNavbarItems = function() {
    return navbarItems;
  };

  var getCustomItems = function () {
    var customerCode = ConfigService.getCustomerCode();
    var apiEndpoint = ConfigService.getMiscApiEndpoint();
    return $http.get(apiEndpoint + '/sidebar/' + customerCode);
  };

  var isItemVisible = function (item) {
    var loggedIn = LoginStateFactory.isLoggedIn();
    var menuItem = lodash.find(navbarItems, ['id', item.id]);

    //console.log('itemvis', loggedIn, menuItem);

    if (FeatureService.isFeatureEnabled(menuItem.featureName)) {

      if (menuItem.visibilityRules.showLoggedOut) {
        return true;
      } else {
        if (!loggedIn) {
          return false;
        } else {
          return true;
        }
      }
    }

    return false;
  };


  var toggleMenu = function (item) {
    var menuItem = lodash.find(navbarItems, ['id', item.id]);
    $log.debug('Toggling menu item', menuItem);
    menuItem.menuCollapsed = !(menuItem.menuCollapsed);
  };

  rebuildNavbar();
  return {
    getCustomItems: getCustomItems,
    getNavbarItems: getNavbarItems,
    isItemVisible: isItemVisible,
    toggleMenu: toggleMenu
  };

};
//]);

},{}],61:[function(require,module,exports){
'use strict';

module.exports = function($rootScope, lodash) {
  var notifications = [
  ];

  var getNotifications = function() {
    return notifications;
  }

  var createNotification = function(notification) {
    var existingNotification = lodash.findIndex(notifications, ['id', notification.id]);
    if (existingNotification === -1) {
      notifications.push(notification);
    }
  };

  var clearNotification = function(notification) {
    if (!notification && !notification.id)
      return;

    var item = lodash.findIndex(notifications, ['id', notification.id]);

    notifications.splice(item, 1);
  };

  return {
    clearNotification: clearNotification,
    createNotification: createNotification,
    getNotifications: getNotifications
  };
};


},{}],62:[function(require,module,exports){
'use strict';

module.exports = function(Restangular) {

  var getUserTimecards = function (user) {
    console.log('ok loading user timecards');
    return Restangular.one('user', user.Id).all('time-entries').getList();
  };

  var getAllTimecards = function (startDate, endDate) {
    console.log('ok loading all timecards', startDate, endDate);

    //var tc = Restangular.all('timecard');
    return Restangular.all('timecard').all('data').getList({'startDate': startDate, 'endDate': endDate});
  };

  var getTimecardsByDate = function(date) {
    return Restangular.one('timecard', date).getList();
  };

  var getTimecardCategories = function () {
    return Restangular.all('timecard-categories').getList();
  };

  var saveTimecardLine = function (line) {
    console.log('in data saving line', line);

    Restangular.one('timecard', line.Id)
      .customPOST(JSON.stringify(line.plain()), null, null,
      {
        'Content-Type': 'application/json'
      })
      .then(function (result) {
        console.log('save result', result);
    });
    //line.put().then(function(result) {
    //  console.log('save result', result);
    //});

    //return Restangular.one('timecard', line.Id);
  };

  return {
    getTimecardCategories: getTimecardCategories,
    getTimecardsByDate: getTimecardsByDate,
    getUserTimecards: getUserTimecards,
    getAllTimecards: getAllTimecards,
    saveTimecardLine: saveTimecardLine
  };
};

},{}],63:[function(require,module,exports){
'use strict';

module.exports = function($q, $window, Timecard, lodash) {
  var timecards = [];
  var timecardsLoaded = false;
  var timecardsLoading = false;

  var allTimecards = [];
  var allTimecardsLoaded = false;
  var allTimecardsLoading = false;


  var timecardCategories = [];
  var timecardCategoriesLoaded = false;
  var timecardCategoriesLoading = false;

  var daysLoading = false;
  var timecardsByDate = {};

  var clearTimecardCache = function() {
    allTimecards = [];
    allTimecardsLoaded = false;
    allTimecardsLoading = false;
  }

  var getTimecardsByDate = function (date) {
    var momentDate = $window.moment(date);
    var dateCode = momentDate.format('YYYY-MM-DD');

    if (!timecardsByDate[dateCode] && !daysLoading) {
      loadDayTimecards(dateCode)
        .then(function() {
          return timecardsByDate[dateCode];
        });
    }
    return timecardsByDate[dateCode];
  };

  var loadDayTimecards = function(date) {
    var deferred = $q.defer();
    daysLoading = true;

    Timecard.getTimecardsByDate(date)
      .then(function (data) {
        timecardsByDate[date] = data;
        daysLoading = false;
        deferred.resolve(data);
      });

    return deferred.promise;
  };

  var loadTimecardCategories = function() {
    var deferred = $q.defer();
    timecardCategoriesLoading = true;

    Timecard.getTimecardCategories()
      .then(function (data) {
        timecardCategories = data;
        timecardCategoriesLoading = false;
        timecardCategoriesLoaded = true;
        deferred.resolve(data);
      });

    return deferred.promise;
  };

  var getTimecardCategories = function () {
    if (!timecardCategoriesLoaded && !timecardCategoriesLoading) {
      loadTimecardCategories().then(function () {
        return timecardCategories;
      });
    }
    return timecardCategories;
  };

  var getCategoryDescriptionById = function (categoryId) {
    var cats = getTimecardCategories();
    console.log('searching for category', categoryId);
    var val = lodash.find(cats, {'id': categoryId});
    console.log('the val', val);
    return val.name;
  };

  var getUserTimecards = function(user) {
    if (!timecardsLoaded && !timecardsLoading) {
      loadUserTimecards(user).then(function () {
        return timecards;
      });
    }
    return timecards;
  };

  var getAllTimecards = function (startDate, endDate) {
    if (!allTimecardsLoaded && !allTimecardsLoading) {
      loadAllTimecards(startDate, endDate).then(function () {
        return allTimecards;
      });
    }
    return allTimecards;
  };

  var loadUserTimecards = function (user) {
    var deferred = $q.defer();
    timecardsLoading = true;

    Timecard.getUserTimecards(user)
      .then(function (data) {
        timecards = data;
        timecardsLoaded = true;
        timecardsLoading = false;
        deferred.resolve(data);
      });

    return deferred.promise;
  };

  var loadAllTimecards = function (startDate, endDate) {
    var deferred = $q.defer();
    allTimecardsLoading = true;

    Timecard.getAllTimecards(startDate, endDate)
      .then(function (data) {
        allTimecards = data;
        allTimecardsLoaded = true;
        allTimecardsLoading = false;
        deferred.resolve(data);
      });

    return deferred.promise;
  };

  var saveTimecardLine = function (line) {
    console.log('in service, saving line', line);
    Timecard.saveTimecardLine(line);
  };

  return {
    clearTimecardCache: clearTimecardCache,
    getTimecardCategories: getTimecardCategories,
    getCategoryDescriptionById: getCategoryDescriptionById,
    getTimecardsByDate: getTimecardsByDate,
    getUserTimecards: getUserTimecards,
    getAllTimecards: getAllTimecards,
    saveTimecardLine: saveTimecardLine
  };
};

},{}],64:[function(require,module,exports){
'use strict';

/**
 * @ngdoc service
 * @name frontendApp.UserProfileService
 * @description
 * # UserProfileService
 * Factory in the frontendApp.
 */
//angular.module('frontendApp')
//  .factory('UserProfileService', ['$http', '$log', 'ConfigService',
module.exports = function($http, $log, $q, ConfigService) {
  var userProfile = {
    templates: []
  };

  var addTemplateToUser = function(template) {
    userProfile.templates.push(template);
    saveUserProfile();
  };

    var loadUserProfile = function () {
      $log.debug('getting user profile');
      var success = function (result) {
        $log.debug('user profile finished loading');
        userProfile = result.data;
        $log.debug("User Profile",userProfile);
      };

      var httpError = function(reason) {
        $log.error('Loading User Profile Failure!');
        return $q.reject(reason);
      };
      var result = $http.get(ConfigService.getApiRoot() + '/api/user/profile').then(success, httpError);
          return result;
      };

  var getUserProfile = function() {
    return userProfile;
  };

  var saveUserProfile = function() {
    $http.post(ConfigService.getApiRoot() + '/api/user/profile', userProfile);
  };


  var getRecentJobIds = function() {
    return userProfile.RecentJobIds;
  };

  var userObject = {
    addTemplateToUser: addTemplateToUser,
    loadUserProfile: loadUserProfile,
    getUserProfile: getUserProfile,
    getRecentJobIds: getRecentJobIds
  };
  return userObject;

};
//]);

},{}],65:[function(require,module,exports){
'use strict';

module.exports = function($http, ConfigService) {
    var getClientVersion = function() {
      return ConfigService.getProductBuild();
    };

    var getServiceVersion = function() {
      return $http.get(ConfigService.getMiscApiEndpoint() + '/version');
    };

    return {
      getClientVersion: getClientVersion,
      getServiceVersion: getServiceVersion
    };
  };

},{}],66:[function(require,module,exports){
'use strict';

angular.module('frontendApp')
  .factory('AppStateService', require('./AppStateService'))
  .factory('ConfigService', require('./ConfigService'))
  .factory('Feature', require('./Feature'))
  .factory('FeatureService', require('./FeatureService'))
  .factory('LoginInterceptor', require('./LoginInterceptor'))
  .factory('LoginStateFactory', require('./loginstatefactory'))
  .factory('Navbar', require('./NavbarService'))
  .factory('NotificationService', require('./NotificationService'))
  .factory('Timecard', require('./Timecard'))
  .factory('TimecardService', require('./TimecardService'))
  .factory('timecardDataContext', require('./timecarddatacontext'))
  .factory('timecardDataFactory', require('./timecarddatafactory'))
  .factory('timecardDataModel', require('./timecarddatamodel'))
  .factory('UserProfileService', require('./UserProfileService'))
  .factory('VersionService', require('./VersionService'));


},{"./AppStateService":55,"./ConfigService":56,"./Feature":57,"./FeatureService":58,"./LoginInterceptor":59,"./NavbarService":60,"./NotificationService":61,"./Timecard":62,"./TimecardService":63,"./UserProfileService":64,"./VersionService":65,"./loginstatefactory":67,"./timecarddatacontext":68,"./timecarddatafactory":69,"./timecarddatamodel":70}],67:[function(require,module,exports){
'use strict';

/**
 * @ngdoc service
 * @name frontendApp.LoginStateFactory
 * @description
 * # LoginStateFactory
 * Factory in the frontendApp.
 */

module.exports =  function ($injector,
  $log,
  $rootScope,
  $window,
  $state,
  AppStateService,
  lock,
  ConfigService,
  jwtHelper,
  $q,
  store,
  NotificationService,
  angularAuth0
  ) {

  var targetClientId = ConfigService.getAuth0ApiClientId(); // Another App
  var apiAuthorizationKey;
  var options = { scope: 'openid name email role user_id dn' };
  var currentLoginMembers = [];
  var push;
  var presenceChannel;
  var privateChannel;
  var publicChannel;
  var userChannel;
  var appUserProfile;
  var isAuthenticated = false;
  var loginInProgress = false;
  var addMember = function(member) {
    //$window.console.log('adding: ');
    //$window.console.log(member);
    //$rootScope.$apply(function () {
    //    currentLoginMembers.push(member);
    //});

  };
  var apiLogin = function () {
    var deferred = $q.defer();
    console.log("[LOGINSTATE] [apiLogin()] Started Login");
    var storedToken = store.get('api-token');

    if (storedToken) {
      if (jwtHelper.isTokenExpired(storedToken)) {
        console.log("[LOGINSTATE] [apiLogin()] Removing Token");

        store.remove('api-token');
        storedToken = null;
      }
    }
    console.log(angularAuth0);

    console.log("[LOGINSTATE] [apiLogin()] Deferring Resolution");
    
    if (isAuthenticated || loginInProgress) {
      console.log("[LOGINSTATE] [apiLogin()] Login is In Progress");
      //deferred.resolve();
      //return deferred.promise;
    }
    loginInProgress = true;
    console.log('Logging in to the API..');
    // Then fetch the secondary app token
    var tk = store.get('token');

    
    if (tk) {
      apiAuthorizationKey = tk;
      isAuthenticated = true;
      console.log('stored api token exists', tk);

      /*
      lock.profilePromise.then(function(data) {
        $log.debug('in profilepromise - finishing login');
        loginUser(data);
        loginPusher();
        deferred.resolve('profile loaded ok, in deferred');
        $window.ga('set', 'userId', data.user_id);
        isAuthenticated = true;
      });*/
      var prof = store.get('profile');
      console.log('doing thing prof', prof);
      var prom = loginUser(prof);
      console.log("prom", prom);
      prom.then(function () {
        console.log('he done');
        console.log(deferred);
        deferred.resolve("srsly he done");        
      }).finally(function() {
        console.log('i finished a function, and i liked it');
      });
      //loginPusher();
      //deferred.resolve('profile loaded ok, in deferred');
      //$window.ga('set', 'userId', data.user_id);

      console.log('returning shjfklsdjfkljsdklfjs');
    }
    console.log('the deferred thing is ', deferred);
    return deferred.promise;
    // var tokenPromise = lock.getToken({
    ////var tokenPromise = angularAuth0.authorize({
    //  'clientId': targetClientId,
    //  'responseType': "token",
    //    'api': 'auth0',
    //    'scope': 'openid name email role user_id dn sAMAccountName userPrincipalName spectrumEmployee'
    //  }).then(function(token) {

    //      //console.log(token);
    //      //config.headers.Authorization = 'Bearer ' + token;
    //      apiAuthorizationKey = token.id_token;
    //      $log.debug('Login Success... ' + apiAuthorizationKey);
    //      store.set('api-token', token.id_token);


    //      deferred.resolve('profile loaded ok');
    //      isAuthenticated = true;
    //    },
    //    function(err) {
    //      // Handle error fetching token here

    //      return err;
    //    })
    //  .then(function () {
    //    lock.profilePromise.then(function (data) {
    //      $log.debug('Logging in after api login');
    //      loginUser(data);
    //      loginPusher();
    //    });

    //  });
  };
  var getApiKey = function () {
    //if (!isAuthenticated) {
    //  apiLogin();
    //}
    return apiAuthorizationKey;
  };
  var getUserProfile = function() {
    $log.debug('Retrieving user profile..');
    return appUserProfile;
  };
  var removeMember = function(member) {
    $log.debug('removing: ');
    $log.debug(member);
    var idx = currentLoginMembers.indexOf(member);
    $rootScope.$apply(function() {
      currentLoginMembers.splice(idx, 1);
    });
    $log.debug(currentLoginMembers);
  };
  var globalAlert = function(message) {
    $log.debug('Global push received', message);
    NotificationService.createNotification(message);
  };
  var globalEvent = function(message) {
    if (message.function_code) {
      switch(message.function_code) {
        case 'maintenance':
          $window.location.reload(true);
          break;
      }
    }
  };

  var userAlert = function(message) {
    $log.debug('Per-user push received', message);
  };
  var userUntrustedCode = function(message) {
    $log.debug('Per-user code push received', message);
    if (message.function_code) {
      switch (message.function_code) {
      case 'reloadApp':
        $log.debug('Reload request received');
        $window.location.reload(true);
        break;
      case 'fullReset':
        $log.debug('Full reload request received');
        fullLogout();
        $window.location.reload(true);
        break;
      }
    }
  };
  var loginPusher = function() {
    $log.debug('Authorizing pusher presence channel..');
    //return;
    var storedToken = store.get('api-token');
    var storedProfile = store.get('profile');
    var storedClientId = store.get('punch-unique-clientid');
    if (!storedToken || !storedProfile) {
      return;
    }

    push = new $window.Pusher(ConfigService.getPusherToken(),
    {
      authEndpoint: ConfigService.getPusherAuthEndpoint(),
      encrypted: true,
      auth: {
        params: {
          clientid: storedClientId
        },
        headers: {
          'Authorization': 'Bearer ' + storedToken
        }
      }
    });

    presenceChannel = push.subscribe(ConfigService.getPusherPresenceChannel());
    privateChannel = push.subscribe(ConfigService.getPusherPrivateChannel());
    publicChannel = push.subscribe(ConfigService.getPusherPublicChannel());
    userChannel = push.subscribe(ConfigService.getPusherUserChannelPrefix() +
      btoa(storedProfile.user_id + '-' + storedClientId));

    privateChannel.bind('globalAlert',
      function(message) {
        globalAlert(message);
      });

    publicChannel.bind('globalEvent',
      function(message) {
        globalEvent(message);
      });

    userChannel.bind('globalAlert',
      function(message) {
        userAlert(message);
      });

    userChannel.bind('codePush',
      function(message) {
        userUntrustedCode(message);
      });

    //presenceChannel.bind('pusher:subscription_succeeded', function (members) {
    //    members.each(function (member) {
    //        addMember(member);
    //    });
    //});

    //presenceChannel.bind('pusher:member_added', function (member) {
    //    addMember(member);
    //});

    //presenceChannel.bind('pusher:member_removed', function (member) {
    //    removeMember(member);
    //});

  };
  var loginUser = function (profile) {
    console.log('what is profile?', profile);
    var postData = {
      'UserId': profile.user_id,
      'sAMAccountName': profile.sAMAccountName,
      'dn': profile.dn,
      'userPrincipalName': profile.userPrincipalName,
      'spectrumEmployee': profile.spectrumEmployee,
      'Name': profile.name,
      'Email': profile.email
    };
    console.log('doing login user', postData);

    var deferred = $q.defer();


    var httpService = $injector.get('$http');
    var servicePomise = httpService.post(ConfigService.getMiscApiEndpoint() + '/AppUser/' + profile.user_id, postData)
      .success(function (data, status, headers, config) {
        console.log('did some shit got it back', data);
        appUserProfile = data;
        console.log('did some shit got it back 2', data);
        $window.Raygun.setUser(profile.user_id, false, profile.email, profile.name, null, profile.user_id);
        console.log('did some shit got it back 3', data);
        deferred.resolve("sick");
        console.log('did some shit got it back 4', data);
      })
      .error(function(data, status, headers, config) {
          $log.debug('some errors happened or something');
        }
    );

    console.log("zzz", deferred.promise);
    return deferred.promise;
  };
 
  var getLockName = function () {
    var storedProfile = store.get('profile');
    if (!storedProfile) {
      return null;
    }
    return storedProfile.name;
  }

  var getLockAccountingEmployeeCode = function() {
    var storedProfile = store.get('profile');
    if (!storedProfile) {
      return null;
    }
    return storedProfile.accountingEmployeeCode;
  }

  var lockAuthenticated = function() {
    //console.log('lockauthenticated called.');

    // Check whether the current time is
    // past the Access Token's expiry time
    var expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    if (!expiresAt) { return false; }
    //console.log('lockauth expiresat', expiresAt);
    //console.log('lockauth is true?', new Date().getTime() < expiresAt);
    return new Date().getTime() < expiresAt;
  };
  var isLoggedIn = function () {

    return lockAuthenticated();
  };

  var saveLocalProfile = function (authResult) {
    console.log("in savelocal, authresult: ", authResult);
    //lock.profilePromise.then(function(data) {
      
    //});
    store.set('token', authResult.idToken);
    var expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime()
    );
    store.set('expires_at', expiresAt);
    //lock.getProfile(authResult.idToken, function (error, profile) {
    //  if (error) {
    //    console.log(error);
    //  }
    //  //localStorage.setItem('profile', JSON.stringify(profile));
    //  store.set('profile', profile);
    //});

    store.set('profile', authResult.idTokenPayload);
  };
  var fullLogout = function() {
    store.remove('token');
    store.remove('profile');
    store.remove('api-token');
    store.remove('timecardsToReview');
    auth.signout();
  };

  var getLockToken = function() {
    return store.get('token');
  }


  $rootScope.$on('$stateChangeStart', function (e, to, toParams) {
    console.log('poop head poop', to.data);
    if (!to.data || !to.data.requiresLogin) {      
      console.log('no requires login');
      return;
    }

    console.log("state", to.name);
    if (isLoggedIn() && to.name === "login") {
      $state.go('home');
    }
    
    if (!isLoggedIn()) {
      e.preventDefault();
      $state.go('login');
    }
   
  });


  var returnData = {
    addMember: addMember,
    apiLogin: apiLogin,
    getApiKey: getApiKey,
    getLockAccountingEmployeeCode: getLockAccountingEmployeeCode,
    getLockToken: getLockToken,
    getLockName: getLockName,
    getUserProfile: getUserProfile,
    removeMember: removeMember,
    push: push,
    privateChannel: privateChannel,
    presenceChannel: presenceChannel,
    currentLoginMembers: currentLoginMembers,
    isLoggedIn: isLoggedIn,
    lockAuthenticated: lockAuthenticated,
    loginPusher: loginPusher,
    loginUser: loginUser,
    saveLocalProfile: saveLocalProfile
  };

  return returnData;
};

//]);

},{}],68:[function(require,module,exports){
'use strict';

/**
 * @ngdoc service
 * @name frontendApp.timecardDataContext
 * @description
 * # timecardDataContext
 * Factory in the frontendApp.
 */
module.exports = function ($http, $log, $q, $window, AppStateService, ConfigService, breeze, timecardDataFactory, UserProfileService) {
    var manager = timecardDataFactory.newManager();

    var primePromise;

  var clearLocalData = function() {
    localStorage.removeItem('timecardsToReview');
    AppStateService.setTimecardsToReview(0);
  };

  var createCompany = function(initialValues, detached) {
    if (!detached) {
      return manager.createEntity('Company', initialValues);
    } else {
      return manager.createEntity('Company', initialValues, breeze.EntityState.Detached);
    }
  };
  var createDivision = function(initialValues, detached) {
    if (!detached) {
      return manager.createEntity('Division', initialValues);
    } else {
      return manager.createEntity('Division', initialValues, breeze.EntityState.Detached);
    }
  };

    var createTemplate = function (initialValues, detached) {
      if (!detached) {
          return manager.createEntity('Template', initialValues);
      } else {
          return manager.createEntity('Template', initialValues, breeze.EntityState.Detached);
      }
    }

    var createTimecard = function (initialValues, detached) {
      if (!detached) {
        return manager.createEntity('Timecard', initialValues);
      } else {
        return manager.createEntity('Timecard', initialValues, breeze.EntityState.Detached);
      }
    };

    var createTimecardLine = function (initialValues, detached) {
      if (!detached) {
          return manager.createEntity('TimecardLine', initialValues);
      } else {
          return manager.createEntity('TimecardLine', initialValues, breeze.EntityState.Detached);
      }
    };

    var createTimecardPunch = function (initialValues, detached) {
      if (!detached) {
          return manager.createEntity('TimecardPunch', initialValues);
      } else {
          return manager.createEntity('TimecardPunch', initialValues, breeze.EntityState.Detached);
      }
    };

  var getCompanies = function() {
    var query = breeze.EntityQuery.from('Companies').orderBy('Name');
    return manager.executeQuery(query);
  }

  var getCostTypes = function() {
    var query = breeze.EntityQuery.from('CostTypes').orderBy('Name');
    return manager.executeQuery(query);
  };

  var getCostTypeById = function(id) {
    var query = breeze.EntityQuery.from('CostTypes').where('Id', '==', id);

    return manager.executeQuery(query);
  };

    var getCustomers = function () {
      $log.debug('Running Division Query');
      var query = breeze.EntityQuery.from('Customers').expand('Company').orderBy('Name');
      return manager.executeQuery(query).then(querySuccess, queryFail);

      function querySuccess(data) {
        $log.debug('Successfully Ran Query');
        return data.results;
      }

      function queryFail(error) {
        $log.debug(error);
      }
    };

    var getCraftsLocal = function(activeOnly) {
      var query;

      if (activeOnly) {
        query = breeze.EntityQuery.from('Crafts')
          .where('IsEnabled','==','1')
          .orderBy('Code');
      } else {
        query = breeze.EntityQuery.from('Crafts').orderBy('Code');
      }
      return manager.executeQueryLocally(query);
    };

    var getDivisions = function () {
        $log.debug('Running Division Query');
        var query = breeze.EntityQuery.from('Divisions').expand('Company').orderBy('Code');
        return manager.executeQuery(query).then(querySuccess, queryFail);

        function querySuccess(data) {
            $log.debug('Successfully Ran Query');
            return data.results;
        }

        function queryFail(error) {
            $log.debug(error);
        }
    };

    var getDivisionsLocal = function () {
        var query = breeze.EntityQuery.from('Divisions').orderBy('Code');
        return manager.executeQueryLocally(query);
    };

    var getDivisionById = function (id) {
      var query = breeze.EntityQuery.from('Divisions').where('Id', '==', id);

      return manager.executeQueryLocally(query);
    };

    var getEmployeesLocal = function (activeOnly) {
      var query;
      if (activeOnly) {
        query = breeze.EntityQuery.from('Employees')
          .where('IsEnabled','==','1')
          .expand('Company, Craft, Class, Division').orderBy('FirstName');
      } else {
        query = breeze.EntityQuery.from('Employees').expand('Company, Craft, Class, Division').orderBy('FirstName');
      }

      return manager.executeQueryLocally(query);
    };

    var getEmployeesByTypeLocal = function (type, activeOnly) {
      var query;
      if (activeOnly) {
        query = breeze.EntityQuery.from('Employees')
          .where('IsEnabled', '==', '1')
          .where('EmployeeType', '==', type)
          .expand('Company, Craft, Class, Division').orderBy('FirstName');
      } else {
        query = breeze.EntityQuery.from('Employees').expand('Company, Craft, Class, Division').orderBy('FirstName');
      }

      return manager.executeQueryLocally(query);
    };

  var getEmployeeById = function(id) {
    var query = breeze.EntityQuery.from('Employees')
      .where('Id', '==', id)
      .expand('Company, Craft, Class, Division')
      .orderBy('FirstName');

    return manager.executeQueryLocally(query);
  };

  var getJobCrews = function(job) {
    var query = breeze.EntityQuery.from('Jobs')
      .expand('Crews, Crews.EmployeeCrews, Crews.EmployeeCrews.Employee')
      .where('Id', '==', job.Id)
      .orderBy('Name');
    return manager.executeQueryLocally(query);
  };

  var getJobs = function() {
    $log.debug('Running Job Query');
    var query = breeze.EntityQuery.from('Jobs')
      .expand('Company, Division, Areas, Areas.CostCodes, Crews, Crews.EmployeeCrews, Crews.EmployeeCrews.Employee, Crews.EmployeeCrews.Employee.Craft, Crews.EmployeeCrews.Employee.CraftClass')
      .orderBy('Name');
    return manager.executeQuery(query).then(querySuccess, queryFail);

    function querySuccess(data) {
      $log.debug('Successfully Ran Query');
      $log.debug(data);
      return data.results;
    }

    function queryFail(error) {
      $log.debug(error);
    }
  };

  var getJobsLimit = function(skip, take) {
    $log.debug('Running Job Query');
    var query = breeze.EntityQuery.from('Jobs').orderBy('Name').skip(skip).take(take);
    return manager.executeQuery(query).then(querySuccess, queryFail);

    function querySuccess(data) {
      $log.debug('Successfully Ran Query');
      $log.debug(data);
      return data.results;
    }

    function queryFail(error) {
      $log.debug(error);
    }
  };

  var getJobById = function(id) {
    var query = breeze.EntityQuery.from('Jobs')
      .where('Id', '==', id)
      .expand('Company, Division, Areas, Areas.CostCodes, Crews, Crews.EmployeeCrews, Crews.EmployeeCrews.Employee, Crews.EmployeeCrews.Employee.Craft, Crews.EmployeeCrews.Employee.CraftClass')
      .orderBy('Name');
    return manager.executeQueryLocally(query);
  }

  var getJobsLast30Days = function() {
    var userProfile = UserProfileService.getUserProfile();

    var predicates = [];
    var firstPredicate;

    for (var i = 0; i < userProfile.RecentJobIds.length; i++) {
      //if (i === 0) {
      //    firstPredicate = breeze.Predicate.create('Id', '==', userProfile.RecentJobIds[i]);
      //} else {
      //    predicates.push(breeze.Predicate.create('Id', '==', userProfile.RecentJobIds[i]));
      //}
      predicates.push(breeze.Predicate.create('Id', '==', userProfile.RecentJobIds[i]));
    }

    var masterPred = breeze.Predicate.or(predicates);
    var query = breeze.EntityQuery.from('Jobs')
      .expand('Company, Division, Areas, Areas.CostCodes, Crews, Crews.EmployeeCrews, Crews.EmployeeCrews.Employee')
      .where(masterPred)
      .orderBy('Name');
    return manager.executeQueryLocally(query);
  };

  var getJobsLocal = function() {
    var query = breeze.EntityQuery.from('Jobs')
      .expand('Company, Division, Areas, Areas.CostCodes, Crews, Crews.EmployeeCrews, Crews.EmployeeCrews.Employee')
      .orderBy('Name');
    return manager.executeQueryLocally(query);
  };

  var getCustomersLocal = function() {
    var query = breeze.EntityQuery.from('Customers').orderBy('Name');
    return manager.executeQueryLocally(query);
  };

  var getTemplates = function() {
    $log.debug('loading templates');
    var query = breeze.EntityQuery.from('Templates').expand('Timecard, Timecard.TimecardLines').orderBy('Name');
    return manager.executeQuery(query).then(querySuccess, queryFail);

    function querySuccess(data) {
      $log.debug('Successfully Ran Query');
      $log.debug(data);
      return data.results;
    }

    function queryFail(error) {
      $log.debug(error);
    }
  };

  var getTemplatesLocal = function() {
    var query = breeze.EntityQuery.from('Templates').expand('Timecard, Timecard.TimecardLines').orderBy('Name');
    return manager.executeQueryLocally(query);
  };

  var getTimecardsToPost = function() {
    return manager.getEntities('Timecard', breeze.EntityState.Added);
  };

  var getTimecardLinesToPost = function() {
    var lines = manager.getEntities('TimecardLine', breeze.EntityState.Added);

    //var query = breeze.EntityQuery.fromEntities(lines).select('Employee.FirstName');
    return lines;
  };

  var getTimecardManger = function() {
    return manager;
  };

  var deleteLocalTimecards = function() {
    manager.rejectChanges();
  };

  var loadCachedData = function() {
    $log.debug('Loading cached data');
    var items = localStorage.getItem('timecardsToReview');
    if (items) {
      $log.debug('items not null');
      manager.importEntities(items);
      var timecardLines = manager.getEntities('TimecardLine', breeze.EntityState.Added);
      AppStateService.setTimecardsToReview(timecardLines.length);

    }
  };

  var getLookups = function() {
    $log.debug('Running Lookups Query');
    var query = breeze.EntityQuery.from('Lookups');
    return manager.executeQuery(query).then(querySuccess, queryFail);

    function querySuccess(data) {
      $log.debug('Successfully Ran Lookups Query');
      return data.results;
    }

      function queryFail(error) {
        $log.debug('Lookup query failed', error);
        return $q.reject(error);
      }
    };


  var prime = function() {
    if (primePromise) {
      return primePromise;
    }
    $log.debug('Priming Data From Server....');

        //primePromise = $q.all([getCustomers(), getJobs(), getDivisions(), UserProfileService.loadUserProfile(), getTemplates()]).then(success);
          primePromise = $q.all([getLookups(), UserProfileService.loadUserProfile()]).then(success, error);

    return primePromise;

    function success() {
      $log.debug('Data primed successfully!');
      $log.debug(UserProfileService.getUserProfile());
      loadCachedData();
    }

        function error() {
          $log.error('There was an error priming the data!');
          throw new Error('There was an error returning data from the server');
        }

      };

  var removeLocalData = function() {

    localStorage.removeItem('timecardsToReview');
  };

  var saveReviewDataLocally = function() {
    var entitiesToSave = manager.getEntities(['Timecard', 'TimecardLine', 'TimecardPunch'], breeze.EntityState.Added);
    var timecardLinesToSave = manager.getEntities(['TimecardLine'], breeze.EntityState.Added);
    var exportedEntitiesToSave = manager.exportEntities(entitiesToSave, false);

    localStorage.removeItem('timecardsToReview');
    localStorage.setItem('timecardsToReview', exportedEntitiesToSave);
    AppStateService.setTimecardsToReview(timecardLinesToSave.length);
  };

  var saveTimecards = function() {
    $log.debug('Calling breeze manager save.');
    var output = manager.saveChanges();
    return output;
  };

  var saveTemplateTimecard = function(template) {
    console.log('poo');
    var graph = manager.getEntityGraph(template, 'Timecard, Timecard.TimecardLines');
    var out = manager.saveChanges(graph, null);
    console.log(out);
    console.log(graph);
    return out;

  };

  var setTimecardLineDeleted = function(timecardLineId) {
    var timecardLine = manager.getEntityByKey('TimecardLine', timecardLineId);
    timecardLine.DeletedFlag = true;
  };

  var setTimecardLineRestored = function(timecardLineId) {
    var timecardLine = manager.getEntityByKey('TimecardLine', timecardLineId);
    timecardLine.DeletedFlag = false;
  };

  var getUserById = function(id) {
    var query = breeze.EntityQuery.from('Users').where('Id', '==', id);
    return manager.executeQuery(query);
  };

      return {
        clearLocalData: clearLocalData,
        createCompany: createCompany,
        createDivision: createDivision,
        createTemplate: createTemplate,
        createTimecard: createTimecard,
        createTimecardLine: createTimecardLine,
        createTimecardPunch: createTimecardPunch,
        deleteLocalTimecards: deleteLocalTimecards,
        getCompanies: getCompanies,
        getCostTypes: getCostTypes,
        getCostTypeById: getCostTypeById,
        getCraftsLocal: getCraftsLocal,
        getCustomers: getCustomers,
        getCustomersLocal: getCustomersLocal,
        getDivisions: getDivisions,
        getDivisionsLocal: getDivisionsLocal,
        getDivisionById: getDivisionById,
        getEmployeesLocal: getEmployeesLocal,
        getEmployeesByTypeLocal: getEmployeesByTypeLocal,
        getEmployeeById: getEmployeeById,
        getJobCrews: getJobCrews,
        getJobs: getJobs,
        getJobsLimit: getJobsLimit,
        getJobById: getJobById,
        getJobsLocal: getJobsLocal,
        getJobsLast30Days: getJobsLast30Days,
        getTemplates: getTemplates,
        getTemplatesLocal: getTemplatesLocal,
        getTimecardsToPost: getTimecardsToPost,
        getTimecardLinesToPost: getTimecardLinesToPost,
        getTimecardManager: getTimecardManger,
        getUserById: getUserById,
        prime: prime,
        removeLocalData: removeLocalData,
        saveTimecards: saveTimecards,
        saveTemplateTimecard: saveTemplateTimecard,
        saveReviewDataLocally: saveReviewDataLocally,
        setTimecardLineDeleted: setTimecardLineDeleted,
        setTimecardLineRestored: setTimecardLineRestored
      };

};
//]);

},{}],69:[function(require,module,exports){
'use strict';

/**
 * @ngdoc service
 * @name frontendApp.timecardDataFactory
 * @description
 * # timecardDataFactory
 * Factory in the frontendApp.
 */
//angular.module('frontendApp')
//  .factory('timecardDataFactory', ['breeze', 'ConfigService', 'timecardDataModel',
module.exports = function(breeze, ConfigService, timecardDataModel) {
  var serviceRoot = ConfigService.getApiEndpoint();

  var metadataStore = createMetadataStore();

  var factory = {
    newManager: newManager,
    metadataStore: metadataStore,
    serviceRoot: serviceRoot
  };

  return factory;

  function newManager() {
    return new breeze.EntityManager({
      serviceName: serviceRoot,
      metadataStore: metadataStore,
      saveOptions: new breeze.SaveOptions({ allowConcurrentSaves: true })
    });
  }

  function createMetadataStore() {
    var store = new breeze.MetadataStore();
    timecardDataModel.configureMetadataStore(store);
    return store;
  }


};
//]);

},{}],70:[function(require,module,exports){
'use strict';

/**
 * @ngdoc service
 * @name frontendApp.timecardDataModel
 * @description
 * # timecardDataModel
 * Factory in the frontendApp.
 */
//angular.module('frontendApp')
//  .factory('timecardDataModel', ['$log', 'breeze',
module.exports = function($log, breeze) {
  var service = {
    configureMetadataStore: configureMetadataStore
  };

  return service;

  function configureMetadataStore(metadataStore) {
    registerEmployeeProperties(metadataStore);
    registerTimecardLineProperties(metadataStore);
    registerPunchProperties(metadataStore);
    registerJobProperties(metadataStore);
    registerCostTypeProperties(metadataStore);

  }

  function registerCostTypeProperties(metadataStore) {
    metadataStore.registerEntityTypeCtor('CostType', CostType);

    function CostType() {}

    Object.defineProperty(CostType.prototype,
      'displayName',
      {
        get: function() {
          return this.Code + '-' + this.Name;
        }
      });
  }

  /// flow of data
  // API -> Proxy Engine -> Screen


  function registerJobProperties(metadataStore) {
    metadataStore.registerEntityTypeCtor('Job', Job);

    function Job() {}

    Object.defineProperty(Job.prototype,
      'nameDescription',
      {
        get: function() {
          return this.Name + '-' + this.Description;
        }
      });
  }

  function registerTimecardLineProperties(metadataStore) {
    $log.debug('Registering Timecard Line Properties');

    metadataStore.registerEntityTypeCtor('TimecardLine', TimecardLine);

    function TimecardLine() {}

    Object.defineProperty(TimecardLine.prototype,
      'totalHours',
      {
        get: function() {
          try {
            var regHours = parseFloat(this.RegularHours);
            var overtimeHours = parseFloat(this.OvertimeHours);
            var doubletimeHours = parseFloat(this.DoubletimeHours);
            var travelHours = parseFloat(this.TravelHours);

            if (isNaN(regHours) || isNaN(overtimeHours) || isNaN(doubletimeHours) || isNaN(travelHours)) return 0.0;
            //$log.debug('Field totalHours', regHours, overtimeHours, doubletimeHours, travelHours);
            return regHours + overtimeHours + doubletimeHours + travelHours;
          } catch (exception) {
            $log.debug("Exception in totalHours", exception);
            return 0.00;
          }
        }
      });

    Object.defineProperty(TimecardLine.prototype,
      'parsedRowInformation',
      {
        get: function() {
          return JSON.parse(this.RowInformation);
        }
      });

  }

  function registerPunchProperties(metadataStore) {
    $log.debug('Registering Punch Properties');
    metadataStore.registerEntityTypeCtor('TimecardPunch', TimecardPunch);

    function TimecardPunch() {}

    Object.defineProperty(TimecardPunch.prototype,
      'punchTypeText',
      {
        get: function() {
          var returnText;
          switch (this.PunchType) {
          case '0':
            returnText = 'In';
            break;
          case '1':
            returnText = 'Out';
            break;
          case '2':
            returnText = 'Subsistence';
            break;
          }

          return returnText;
        }
      });
  }

  function registerEmployeeProperties(metadataStore) {


    $log.debug('Registering Employee Properties');
    metadataStore.registerEntityTypeCtor('Employee', Employee);

    function Employee() {
      this.backingFields = {
        _manualCraftId: null,
        _manualClassId: null
      };
    }


    Object.defineProperty(Employee.prototype,
      'fullName',
      {
        get: function() {
          return this.FirstName + ' ' + this.LastName;
        }
      });

    Object.defineProperty(Employee.prototype,
      'manualCraftId',
      {
        get: function() {
          return this.backingFields._manualCraftId;
        },
        set: function(value) {
          this.backingFields._manualCraftId = value;
        }
      });

    Object.defineProperty(Employee.prototype,
      'manualClassId',
      {
        get: function() {
          return this.backingFields._manualClassId;
        },
        set: function(value) {
          this.backingFields._manualClassId = value;
        }
      });

  }
};
//]);

},{}]},{},[1])

